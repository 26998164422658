import { Component } from 'react'
import { API, Storage } from 'aws-amplify'
import withRouter from '../components/withRouter'
import Calendar from '../components/calendar'
import Form from "react-bootstrap/Form"
import Carousel from 'react-bootstrap/Carousel'
import LoaderButton from '../components/loaderButton'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import SmallCard from '../components/smallCard'
import moment from 'moment'
import QRCode from 'qrcode.react'
import Checkmark from '../images/checkmark.svg'
import { v1 as uuidv1 } from 'uuid'
import { MdAddAPhoto, MdDeleteForever, MdOutlineVerified, MdOutlineAttachMoney, MdImageNotSupported, MdEmail, MdPublic, MdPublicOff } from 'react-icons/md'
import { FaArrowLeft, FaLocationArrow, FaPlusSquare, FaLink, FaUserAltSlash, FaChurch, FaRegQuestionCircle, FaMapMarkedAlt, FaStar, FaHeart, FaUser, FaRegCheckSquare } from 'react-icons/fa'
import { FaSquareTwitter, FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaYoutube, FaFileCirclePlus, FaFileCircleCheck, FaSquareXmark, FaPhone, FaFax } from 'react-icons/fa6'
import { AiFillCloseCircle } from 'react-icons/ai'
import { IoRestaurantOutline, IoLogoVenmo } from 'react-icons/io5'
import { HiOutlineBuildingStorefront } from 'react-icons/hi2'
import { GiWineBottle } from 'react-icons/gi'
import { LuCalendarClock, LuCalendarX2 } from 'react-icons/lu'
import { SiCashapp } from 'react-icons/si'
import { BiMessageAdd } from 'react-icons/bi'
import { TbClockX, TbNotesOff } from 'react-icons/tb'
import { GoUnverified } from 'react-icons/go'
import { BsYelp, BsLinkedin, BsInfoCircle } from 'react-icons/bs'
import Logo from '../images/GigLoca_Logo_Light.png'
import { getAddress } from '../helpers'
import './node.css'

class MyNode extends Component {
    constructor(props) {
        super(props)

        this.state = {
            menu: {
                left: 'dashboard',
                right: 'edit',
                mode: this.props.mode,
                action: 'addEvent'
            },
            splash: true,
            node: '',
            route: null,
            data: null,
            view: 'show',
            id: null,
            more: null,
            bounce: false,
            options: null,
            name: '',
            description: '',
            category: '',
            genre: [],
            genres: [],
            loc: '',
            locIsGood: false,
            line1: '',
            line2: '',
            city: '',
            state: '',
            postalcode: '',
            amenities: [],
            restrictions: [],
            link: '',
            linkArray: [],
            imageArray: [],
            commType: 'Phone',
            contact: '',
            commConsent: true,
            communicationArray: [],
            operationArray: [],
            blackout: '',
            blackoutArray: [],
            rate: '',
            negotiable: true,
            authorized: '',
            authorizedArray: [],
            userFailed: false,
            month: moment().date(1).format('YYYY-MM-DD'),
            days: [],
            altDays: [],
            selDay: null,
            start: '',
            stop: '',
            interested: [],
            accepted: [],
            displayPost: false,
            post: '',
            isSaving: false,
            confirmed: false,
            doc: null,
            imageSize: '',
            displayMsg: false,
            msg: '',
            badDoc: false,
            info: [
                { id: 0, displayInfo: false, displayMsg: false, msg: 'Click "Check Address" to validate and select the address.', dir: 'left', val: '60px' }
            ]
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleEventSelect = this.handleEventSelect.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
        this.handleRightMenu = this.handleRightMenu.bind(this)
        this.handleGiglit = this.handleGiglit.bind(this)
        this.handleDayChanged = this.handleDayChanged.bind(this)
        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleConfirmedSelect = this.handleConfirmedSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            const { path, search } = this.props
            const id = path.id
            const route = search.pathname.toLowerCase() === `/my/venue/${id}` ? 'Venue' : 'Contributor'
            this.props.setMode(route)
            if (id === 'new') {
                API.get('lt', 'options')
                    .then(resp => {
                        this.setState({
                            route,
                            view: 'edit',
                            splash: false,
                            options: resp.data.filter(d => d.prop === 'node'),
                            forms: resp.data.filter(d => d.prop === 'form'),
                            node: route === 'Venue' ? 'Venue' : '',
                            menu: {
                                left: 'dashboard',
                                right: 'view',
                                mode: null,
                                action: 'addEvent'
                            }
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) {
                            this.handleNavigate('main')
                        } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                    })
            } else {
                if (this.props.myNodes) {
                    if (route === 'Venue') {
                        const idx = this.props.myNodes.Venue ? this.props.myNodes.Venue.findIndex(v => v.id === id) : -1
                        if (idx > -1) {
                            this.setState({
                                id,
                                route,
                                data: this.props.myNodes.Venue[idx],
                                node: 'Venue',
                                splash: false,
                                menu: {
                                    left: 'dashboard',
                                    right: 'edit',
                                    mode: 'Venue',
                                    action: 'addEvent'
                                }
                            })
                            this.getData(id, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                        } else setTimeout(() => this.handleNavigate(`my/venues`), 150)
                    } else {
                        let node = ''
                        let data = null
                        Object.keys(this.props.myNodes).forEach(key => this.props.myNodes[key].forEach(n => {
                            if (n.id === id) {
                                node = key
                                data = n
                            }
                        }))
                        if (node.length > 0 && data) {
                            this.setState({
                                id,
                                route,
                                data,
                                node,
                                splash: false,
                                menu: {
                                    left: 'dashboard',
                                    right: 'edit',
                                    mode: 'Contributor',
                                    action: 'findGigs'
                                }
                            })
                            this.getData(id, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                        } else setTimeout(() => this.handleNavigate(`my/contributors`), 150)
                    }
                } else {
                    API.get('lt', `my`)
                        .then(resp => {
                            const myNodes = resp.data
                            this.props.setMy(myNodes)
                            if (route === 'Venue') {
                                const idx = resp.data.Venue ? resp.data.Venue.findIndex(v => v.id === id) : -1
                                if (idx > -1) {
                                    this.setState({
                                        id,
                                        route,
                                        data: resp.data.Venue[idx],
                                        node: 'Venue',
                                        splash: false,
                                        menu: { ...this.state.menu, mode: route }
                                    })
                                    this.getData(id, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                                } else setTimeout(() => this.handleNavigate(`my/venues`), 150)
                            } else {
                                let node = ''
                                let data = null
                                Object.keys(resp.data).forEach(key => resp.data[key].forEach(n => {
                                    if (n.id === id) {
                                        node = key
                                        data = n
                                    }
                                }))
                                if (node.length > 0 && data) {
                                    this.setState({
                                        id,
                                        route,
                                        data,
                                        node,
                                        splash: false,
                                        menu: {
                                            left: 'dashboard',
                                            right: 'edit',
                                            mode: this.props.mode,
                                            action: 'findGigs'
                                        }
                                    })
                                    this.getData(id, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                                } else setTimeout(() => this.handleNavigate(`my/contributors`), 150)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            if (err.response && err.response.status && err.response.status === 403) {
                                this.handleNavigate('main')
                            } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                        })
                }
            }
        } else {
            this.setState({ splash: false, bounce: true })
            setTimeout(() => {
                this.handleNavigate('main', true)
            }, 3000)
        }
    }

    handleNavigate(page, history) {
        const { search } = this.props
        if (history) this.props.onAddHistory(search.pathname + search.search)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.handleNavigate(`my/${this.state.route}s`, true)
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else if (mode !== 'fan') {
            this.props.setMode(mode)
            this.handleNavigate(`my/${mode === 'Venue' ? 'venues' : 'contributors'}`, true)
        } else this.handleNavigate('', true)
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications', true)
    }

    handleDayChanged(selDay) {
        this.setState({ selDay })
    }

    handleMonthChanged(dir) {
        let month = moment(this.state.month)
        if (dir === 'back') {
            month.subtract(1, 'month')
        } else if (dir === 'next') {
            month.add(1, 'month')
        }
        const start = moment(month).format('YYYY-MM-DD')
        const stop = moment(month).add(1, 'month').format('YYYY-MM-DD')
        this.setState({ month: month.format('YYYY-MM-DD'), start, stop, selDay: null })
        this.getData(this.state.id, start, stop)
    }

    handleMonthSelected() {
        this.setState({ selDay: null })
    }

    handleLeftMenu() {
        this.handleNavigate(`my/${this.state.route}s`, true)
    }

    handleRightMenu() {
        if (this.state.options) {
            this.setState({
                menu: {
                    left: 'dashboard',
                    right: this.state.view === 'show' ? 'view' : 'edit',
                    mode: this.state.view === 'edit' && this.state.id ? this.props.mode : null,
                    action: this.state.route === 'Venue' ? 'addEvent' : 'findGigs'
                },
                view: this.state.view === 'show' ? 'edit' : 'show',
                data: {
                    ...this.state.data,
                    name: this.state.name,
                    description: this.state.description,
                    category: this.state.category,
                    genre: this.state.genre,
                    address: {
                        line1: this.state.line1,
                        line2: this.state.line2,
                        city: this.state.city,
                        state: this.state.state,
                        postalcode: this.state.postalcode,
                    },
                    amenities: this.state.amenities,
                    restrictions: this.state.restrictions,
                    linkArray: this.state.linkArray,
                    imageArray: this.state.imageArray,//.map(ia => ia.image),
                    communicationArray: this.state.communicationArray,
                    operationArray: this.state.operationArray,
                    blackoutArray: this.state.blackoutArray,
                    rate: {
                        rateValue: this.state.rate,
                        negotiable: this.state.negotiable
                    },
                    authorizedArray: this.state.authorizedArray,
                    doc: this.state.doc
                }
            })
        } else {
            API.get('lt', 'options')
                .then(resp => {
                    this.setState({
                        menu: {
                            left: 'dashboard',
                            right: 'view',
                            mode: null,
                            action: this.state.route === 'Venue' ? 'addEvent' : 'findGigs'
                        },
                        view: 'edit',
                        options: resp.data.filter(d => d.prop === 'node'),
                        forms: resp.data.filter(d => d.prop === 'form')
                    })
                    this.handleReset()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        }
    }

    handleGiglit() {
        if (this.state.route === 'Venue') {
            this.handleNavigate(`my/event/${this.state.id}?event=new`)
        } else {
            this.handleNavigate(`gigs/${this.state.id}`)
        }
    }

    handleEventSelect(eid) {
        if (this.state.route === 'Venue') {
            this.handleNavigate(`my/event/${this.state.id}?event=${eid}`, true)
        } else this.handleNavigate(`event/${eid}`, true)
    }

    handleConfirmedSelect(eid) {
        this.handleNavigate(`gigs/${this.state.id}?event=${eid}`, true)
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'node':
                this.setState({ [event.target.id]: event.target.value, genres: [] })
                break
            case 'postalcode':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d]/g, "").substring(0, 9) })
                break
            case 'dob':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d-/\\]/g, '') })
                break
            case 'category':
                let genres = []
                if (this.state.options && this.state.node.length > 0 && event.target.value.length > 0) {
                    const idx = this.state.options.findIndex(o => o.ptype === this.state.node)
                    if (idx > -1) {
                        const cidx = this.state.options[idx].categories.findIndex(c => Object.keys(c)[0] === event.target.value)
                        if (cidx > -1) {
                            genres = Object.values(this.state.options[idx].categories[cidx])[0]
                        }
                    }
                }
                this.setState({ [event.target.id]: event.target.value, genres })
                break
            case 'link':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-?=@:./ ]/g, "") })
                break
            case 'contact':
                if (this.state.commType === 'Email') {
                    this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "") })
                } else {
                    this.setState({ [event.target.id]: event.target.value.replace(/[^0-9-)( ]/g, "") })
                }
                break
            case 'start':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\daAmMpP: ]/g, "") })
                break
            case 'stop':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\daAmMpP: ]/g, "") })
                break
            case 'rate':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9/$-. ]/g, "") })
                break
            case 'authorized':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "").toLowerCase() })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "") })
        }
    }

    handleOptionChange(type) {
        this.setState({ [type]: !this.state[type] })
    }

    handleOptionsChange(type, o) {
        let opt = [...this.state[type]]
        const idx = opt.length > 0 ? opt.indexOf(o) : -1
        if (idx > -1) {
            opt.splice(idx, 1)
        } else opt.push(o)
        this.setState({ [type]: opt })
    }

    handleLocChanged(event) {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: event.target.value.length > 0, displayMsg: false })
        this.setState({ info, loc: event.target.value.replace(/[^a-zA-Z0-9-,#. ]/g, ""), locIsGood: false })
    }

    handleGetMyLoc() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
        this.setState({ info })
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        let addr = resp.data.results[0].address_components
                        let curr = {}
                        addr.forEach(a => {
                            if (a.types.indexOf('street_number') > -1) curr.line1 = a.short_name + ' ' + (curr.line1 || '')
                            if (a.types.indexOf('route') > -1) curr.line1 += a.short_name
                            if (a.types.indexOf('subpremise') > -1) curr.line2 = a.short_name
                            if (a.types.indexOf('locality') > -1) curr.city = a.short_name
                            if (a.types.indexOf('administrative_area_level_1') > -1) curr.state = a.short_name
                            if (a.types.indexOf('country') > -1) curr.country = a.short_name
                            if (a.types.indexOf('postal_code') > -1) curr.postalcode = a.short_name
                        })
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true, ...curr })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
            this.setState({ locIsGood: false, loc: 'Not Available' })
        })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            let info = [...this.state.info]
            info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
            this.setState({ info })
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        let addr = resp.data.results[0].address_components
                        let curr = {}
                        addr.forEach(a => {
                            if (a.types.indexOf('street_number') > -1) curr.line1 = a.short_name + ' ' + (curr.line1 || '')
                            if (a.types.indexOf('route') > -1) curr.line1 += a.short_name
                            if (a.types.indexOf('subpremise') > -1) curr.line2 = a.short_name
                            if (a.types.indexOf('locality') > -1) curr.city = a.short_name
                            if (a.types.indexOf('administrative_area_level_1') > -1) curr.state = a.short_name
                            if (a.types.indexOf('country') > -1) curr.country = a.short_name
                            if (a.types.indexOf('postal_code') > -1) curr.postalcode = a.short_name
                        })
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true, ...curr })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleImageChange(e, idx) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let image = uuidv1() + file.type.replace('image/', '.')
                let imageArray = [...this.state.imageArray]
                imageArray.splice(idx, 1, { file, image, imgUrl: URL.createObjectURL(file) })
                this.setState({ imageArray, imageSize: false })
            }
        } else this.setState({ imageSize: idx.toString() })
        this.fileInput.value = ''
    }

    handleClearPic(idx) {
        let imageArray = [...this.state.imageArray]
        imageArray.splice(idx, 1)
        this.setState({ imageArray, imageSize: '', badDoc: false })
    }

    handleDocChange(e) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let doc = uuidv1() + file.type.replace('image/', '.')
                this.setState({ doc: { file, doc, docUrl: URL.createObjectURL(file) } })
            } else if (file.type.indexOf('application/') > -1) {
                let doc = uuidv1() + file.type.replace('application/', '.')
                this.setState({ doc: { file, doc, docUrl: URL.createObjectURL(file) } })
            } else this.setState({ badDoc: true })
        } else this.setState({ imageSize: 'doc', badDoc: false })
        this.fileInput.value = ''
    }

    handleClearDoc() {
        this.setState({ doc: null, imageSize: '', badDoc: false })
    }

    handleAddLink() {
        if (this.state.link.length > 5) {
            let link = this.state.link
            if (link.indexOf('https://') === -1) {
                if (link.indexOf('http://') === 0) {
                    link.replace('http://', 'https://')
                } else link = `https://${link}`
            }
            this.setState({ linkArray: [...this.state.linkArray, link], link: '' })
        }
    }

    handleAddCommunication() {
        if (this.state.contact.length > 0) {
            this.setState({ communicationArray: [...this.state.communicationArray, { contact: this.state.contact, communicationType: this.state.commType, consent: this.state.commConsent }], contact: '', commType: 'Phone', commConsent: true })
        }
    }

    handleAddOpHours() {
        if (this.state.start.length > 0 && this.state.stop.length > 0 && this.state.days.length > 0) {
            this.setState({ operationArray: [...this.state.operationArray, { values: this.state.days, start: this.state.start, stop: this.state.stop }], days: [], start: '', stop: '' })
        }
    }

    handleAddBlackout() {
        if (moment(this.state.blackout).isValid && moment(this.state.blackout).isSameOrAfter(moment(), 'day')) {
            let idx = this.state.blackoutArray ? this.state.blackoutArray.indexOf(this.state.blackout) : -1
            if (idx === -1) {
                this.setState({ blackoutArray: [...this.state.blackoutArray, this.state.blackout], blackout: '' })
            } else this.setState({ blackout: '' })
        }
    }

    handleAddClosed() {
        if (this.state.days.length > 0) {
            this.setState({ operationArray: [...this.state.operationArray, { values: this.state.days, start: null, stop: null }], days: [], start: '', stop: '' })
        }
    }

    handleAddAuth() {
        //change this below to include images
        if (this.state.authorized.length > 7) {
            API.get('lt', `email?email=${this.state.authorized}`)
                .then(resp => {
                    if (resp.data.verified) {
                        this.setState({ userFailed: false, authorizedArray: [...this.state.authorizedArray, resp.data], authorized: '' })
                    } else {
                        this.setState({ userFailed: true })
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else this.setState({ userFailed: true })
    }

    handleRemoveArray(type, idx) {
        let array = [...this.state[type]]
        array.splice(idx, 1)
        this.setState({ [type]: array })
    }

    handleReset() {
        if (this.state.id && this.state.data) {
            let genres = []
            const idx = this.state.options.findIndex(o => o.ptype === this.state.node)
            if (idx > -1) {
                const cidx = this.state.options[idx].categories.findIndex(c => Object.keys(c)[0] === this.state.data.category)
                if (cidx > -1) {
                    genres = Object.values(this.state.options[idx].categories[cidx])[0]
                }
            }
            this.setState({
                name: this.state.data.name,
                description: this.state.data.description || '',
                category: this.state.data.category,
                genre: this.state.data.genre || [],
                genres,
                loc: '',
                locIsGood: false,
                line1: this.state.data.address && this.state.data.address.line1 ? this.state.data.address.line1 : '',
                line2: this.state.data.address && this.state.data.address.line2 ? this.state.data.address.line2 : '',
                city: this.state.data.address && this.state.data.address.city ? this.state.data.address.city : '',
                state: this.state.data.address && this.state.data.address.state ? this.state.data.address.state : '',
                postalcode: this.state.data.address && this.state.data.address.postalcode ? this.state.data.address.postalcode : '',
                amenities: this.state.data.amenities || [],
                restrictions: this.state.data.restrictions || [],
                link: '',
                linkArray: this.state.data.linkArray || [],
                commType: 'Phone',
                contact: '',
                commConsent: true,
                communicationArray: this.state.data.communicationArray || [],
                operationArray: this.state.data.operationArray || [],
                blackoutArray: this.state.data.blackoutArray && this.state.data.blackoutArray.length > 0 ? this.state.data.blackoutArray.filter(ba => moment(ba).isValid() && moment(ba).isSameOrAfter(moment(), 'day')) : [],
                imageArray: this.state.data.imageArray ? this.state.data.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                days: [],
                start: '',
                stop: '',
                rate: this.state.data.rate && this.state.data.rate.rateValue ? this.state.data.rate.rateValue : '',
                negotiable: this.state.data.rate && this.state.data.rate.negotiable ? this.state.data.rate.negotiable : false,
                authorized: '',
                authorizedArray: this.state.data.authorizedArray || [],
                userFailed: false,
                imageSize: ''
            })
        } else {
            this.handleNavigate(`my/${this.props.mode}s`, false)
        }
    }

    handleCancel() {
        if (this.state.id && this.props.myNodes) {
            if (this.state.route === 'Venue') {
                const idx = this.props.myNodes.Venue ? this.props.myNodes.Venue.findIndex(v => v.id === this.state.id) : -1
                if (idx > -1) {
                    this.setState({
                        data: this.props.myNodes.Venue[idx],
                        menu: {
                            left: 'dashboard',
                            right: 'edit',
                            mode: 'Venue',
                            action: 'addEvent'
                        },
                        view: 'show'
                    })
                } else this.handleNavigate(`my/venues`)
            } else {
                let node = ''
                let data = null
                Object.keys(this.props.myNodes).forEach(key => this.props.myNodes[key].forEach(n => {
                    if (n.id === this.state.id) {
                        node = key
                        data = n
                    }
                }))
                if (node.length > 0 && data) {
                    this.setState({
                        data,
                        node,
                        menu: {
                            left: 'dashboard',
                            right: 'edit',
                            mode: 'Contributor',
                            action: 'findGigs'
                        },
                        view: 'show'
                    })
                } this.handleNavigate(`my/contributors`)
            }
        } else this.handleNavigate(`my/${this.props.mode}s`, false)
    }

    handleSubmit = event => {
        event.preventDefault()
        if (this.formIsValid()) {
            this.setState({ isSaving: true })
            const body = {
                address: {
                    line1: this.state.line1,
                    line2: this.state.line2,
                    city: this.state.city,
                    state: this.state.state,
                    postalcode: this.state.postalcode
                },
                ages: this.state.ages,
                amenities: this.state.node === 'Venue' ? this.state.amenities : null,
                authorizedArray: this.state.authorizedArray.map(aa => aa.email),
                category: this.state.category,
                description: this.state.description,
                genre: this.state.genre,
                imageArray: this.state.imageArray.map(ia => ia.image),
                linkArray: this.state.linkArray,
                communicationArray: this.state.communicationArray,
                name: this.state.name,
                nodetype: this.state.node,
                operationArray: this.state.operationArray,
                blackoutArray: this.state.blackoutArray,
                restrictions: this.state.node === 'Venue' ? this.state.restrictions : null,
                rate: {
                    rateValue: this.state.rate,
                    negotiable: this.state.negotiable
                },
                doc: this.state.doc && this.state.doc.doc ? this.state.doc.doc : null
            }
            let iComp = 0
            let iTot = this.state.imageArray.filter(i => i.file).length
            let comp = false
            this.state.imageArray.filter(i => i.file).forEach(image => {
                Storage.put(`${image.image}`, image.file, {
                    resumable: true,
                    progressCallback: (progress) => {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
                    },
                    completeCallback: (event) => {
                        iComp += 1
                        console.log(`Successfully uploaded ${event.key}`, `${iComp}/${iTot}`)
                        if (comp && iComp === iTot) {
                            this.setState({ view: 'show', isSaving: false, menu: { ...this.state.menu, right: 'edit' } })
                        }
                    }
                })
            })
            if (this.state.doc && this.state.doc.file) Storage.put(`${this.state.doc.doc}`, this.state.doc.file, {
                progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`) }
            })
            if (this.state.id) {
                API.put('lt', `node/${this.state.id}`, { body })
                    .then(resp => {
                        this.setState({
                            data: { ...resp.data, authorizedArray: this.state.authorizedArray, imageArray: resp.data.imageArray ? resp.data.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [] },
                            imageArray: resp.data.imageArray ? resp.data.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                            displayPost: false
                        })
                        comp = true
                        if (iComp === iTot) {
                            this.setState({ view: 'show', isSaving: false, menu: { ...this.state.menu, right: 'edit' } })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) {
                            this.handleNavigate('main')
                        } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                    })
            } else {
                API.post('lt', `node?type=${this.state.node}`, { body })
                    .then(resp => {
                        this.handleNavigate(`my/${this.state.route}/${resp.data.id}`, false)
                        this.setState({
                            data: { ...resp.data, authorizedArray: this.state.authorizedArray, imageArray: resp.data.imageArray ? resp.data.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [] },
                            imageArray: resp.data.imageArray ? resp.data.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                            displayPost: false,
                            view: iComp === this.state.imageArray.filter(i => i.file).length ? 'show' : this.state.view,
                            isSaving: false,
                            menu: { ...this.state.menu, right: 'edit' }, id: resp.data.id
                        })
                        comp = true
                        if (iComp === iTot) {
                            this.setState({ view: 'show', isSaving: false, menu: { ...this.state.menu, right: 'edit' } })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) {
                            this.handleNavigate('main')
                        } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                    })
            }
        }
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleAddPost() {
        this.setState({ displayPost: true })
    }

    handleCancelPost() {
        this.setState({ displayPost: false, post: '' })
    }

    handleSavePost = (event) => {
        event.preventDefault()
        if (this.state.post.length > 3) {
            this.setState({ isSaving: true })
            API.put('lt', `posts/node/${this.state.id}`, { body: { post: this.state.post, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ data: { ...this.state.data, posts: resp.node.posts || [] }, displayPost: false, post: '', isSaving: false })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        }
    }

    handleDelPost(pid) {
        API.del('lt', `posts/node/${this.state.id}/${pid}`)
            .then(resp => {
                this.setState({ data: { ...this.state.data, posts: resp.node.posts || [] } })
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.status && err.response.status === 403) {
                    this.handleNavigate('main')
                } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
            })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false, isSaving: false })
    }

    postIsValid() {
        return this.state.post.length > 3
    }

    formIsValid() {
        //finish this
        return (
            this.state.name.length > 0 &&

            this.state.category.length > 0
        )
    }

    getSelectedStarsArray(stars) {
        let selected = []
        for (let x = 0; x < stars; x++) {
            selected.push(x + 1)
        }
        return selected
    }

    getUnselectedStarsArray(stars) {
        stars++
        let unselected = []
        for (let x = stars; x < 6; x++) {
            unselected.push(x)
        }
        return unselected
    }

    getOpt(type, o) {
        let idx = this.state[type].indexOf(o)
        return idx > -1
    }

    getBGIcon() {
        if (this.state.data && this.state.data.category) {
            switch (this.state.data.category) {
                case 'Restaurant':
                    return <IoRestaurantOutline />
                case 'Bar':
                    return <GiWineBottle />
                case 'Church':
                    return <FaChurch />
                default:
                    return <HiOutlineBuildingStorefront />
            }
        } else return <FaRegQuestionCircle />
    }

    getValidation(field) {
        switch (field) {
            case 'name':
                return this.state[field].length > 1
            case 'description':
                return this.state[field].length > 1
            case 'line1':
                return this.state[field].length > 3
            case 'city':
                return this.state[field].length > 3
            case 'state':
                return this.state[field].length > 1
            case 'postalcode':
                return (/^\d{5,10}$/).test(this.state[field])
            case 'start':
                return this.state[field].length === 5 && moment(`2000-01-01 ${this.state[field]}`).isValid()
            case 'stop':
                return this.state[field].length === 5 && moment(`2000-01-01 ${this.state[field]}`).isValid()
            case 'blackout':
                return moment(this.state[field]).isValid() && moment(this.state[field]).isSameOrAfter(moment(), 'day')
            default:
                return false
        }
    }

    getSocialIcon(link) {
        let thisLink = link || this.state.link
        if (thisLink.length > 8) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            if (t.test(thisLink)) {
                return <div style={{ marginTop: '10px' }} className='Node-Flex'><FaSquareTwitter /><FaSquareXTwitter /></div>
            } else if (fb.test(thisLink)) {
                return <FaSquareFacebook />
            } else if (inst.test(thisLink)) {
                return <FaSquareInstagram />
            } else if (cash.test(thisLink)) {
                return <SiCashapp />
            } else if (v.test(thisLink)) {
                return <IoLogoVenmo />
            } else if (u.test(thisLink)) {
                return <FaYoutube />
            } else if (l.test(thisLink)) {
                return <BsLinkedin />
            } else if (y.test(thisLink)) {
                return <BsYelp />
            } else return <FaLink />
        } else return <FaLink />
    }

    getLinks() {
        if (this.state.data && this.state.data.linkArray) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            let sm = []
            let links = []
            this.state.data.linkArray.forEach(link => {
                if (t.test(link) || fb.test(link) || inst.test(link) || cash.test(link) || v.test(link) || y.test(link) || l.test(link) || u.test(link)) {
                    if (link.indexOf('https://') === 0) {
                        sm.push(link)
                    } else if (link.indexOf('http://') === 0) {
                        sm.push(link.replace('http://', 'https://'))
                    } else sm.push(`https://${link}`)
                } else {
                    if (link.indexOf('https://') === 0) {
                        links.push(link)
                    } else if (link.indexOf('http://') === 0) {
                        links.push(link.replace('http://', 'https://'))
                    } else links.push(`https://${link}`)
                }
            })
            if (sm.length + links.length > 0) {
                return <div className='Node-Row'>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><FaLink /></div>
                        <div className='Node-Divider-Text'>Social Media & Links</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Flex-Wrap'>
                        {
                            sm.map((sl, slidx) => {
                                return <div key={`sml-${slidx}`} className='Node-SM-Icons'><a href={sl} target='_blank' rel="noreferrer" title={sl}>{this.getSocialIcon(sl)}</a></div>
                            })
                        }
                    </div>
                    {
                        links.map((link, lidx) => {
                            return <div key={`link-${lidx}`} className='Node-Row Node-Flex'><div className='Node-Addr-Icon'>{this.getSocialIcon(link)}</div><div className='My-Link'><a href={link} target='_blank' rel="noreferrer" className='My-Link'>{link}</a></div></div>
                        })
                    }
                </div>
            }
        } else return null
    }

    getOpHours() {
        let days = []
        this.state.data.operationArray.forEach(o => days = [...days, ...o.values.map(v => { return { open: o.start || null, close: o.stop || null, day: v } })])
        return days.map((d, dayidx) => {
            return <div key={`day-${dayidx}`} className='Node-Item'>{`${d.day} ${d.open ? `${moment(`2023-01-01 ${d.open}`).format('h:mm A')} - ${moment(`2023-01-01 ${d.close}`).format('h:mm A')}` : 'Closed'}`}</div>
        })
    }

    getData(id, start, stop) {
        if (id) {
            API.get('lt', `gigs/${id}?start=${start}&end=${stop}`)
                .then(resp => {
                    let interested = []
                    let accepted = []
                    let events = []
                    let days = []
                    let altDays = []
                    resp.data.forEach(gig => {
                        if (this.state.node === 'Venue') {
                            events.push(gig)
                            for (let x = moment(gig.startTime).date(); x <= moment(gig.stopTime).date(); x++) {
                                days.push(x)
                            }
                        } else {
                            if (gig.nodes && gig.nodes.indexOf(id) > -1) {
                                accepted.push(gig)
                                if (gig.startTime && gig.stopTime) {
                                    for (let x = moment(gig.startTime).date(); x <= moment(gig.stopTime).date(); x++) {
                                        days.push(x)
                                    }
                                }
                            } else {
                                interested.push(gig)
                                for (let x = moment(gig.startTime).date(); x <= moment(gig.stopTime).date(); x++) {
                                    altDays.push(x)
                                }
                            }
                        }
                    })
                    this.setState({ interested, accepted, days, altDays, data: { ...this.state.data, events, interested, accepted } })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ interested: [], accepted: [], days: [], altDays: [], data: { ...this.state.data, events: [], interested: [], accepted: [] }, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        }
    }

    getSplash() {
        return <div className='My-Splash'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
        </div>
    }

    getBounce() {
        return <div className='Pref-Bounce'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Node-Content'>Become part of the fun by Logging In or Joining GigLoca.</div>
        </div>
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getView() {
        //console.log(this.state.data)
        return this.state.data ? <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Node-Content-2'>
                {this.state.data.imageArray && this.state.data.imageArray.length > 0 ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                    {
                        this.state.data.imageArray.map((ci, imgidx) => {
                            return <Carousel.Item key={`ci-${imgidx}`}>
                                <img src={ci.imgUrl ? ci.imgUrl : `https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.data.name || this.state.data.title || ''} />
                            </Carousel.Item>
                        })
                    }
                </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                <div className='Node-Back Node-Flex-Spread'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div><div className='Node-Arrow' style={{ marginRight: '5px', color: this.state.data.active ? 'green' : '#ff0000' }}><div style={{ marginTop: '-13px' }}>{this.state.data.active ? <MdOutlineVerified /> : <GoUnverified />}</div></div></div>
                <div className='Node-Content-3'>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Title'>{this.state.data.name ? this.state.data.name : 'Venue'}</div>
                    </div>
                    <div className='Node-Row Node-Flex-Wrap'>
                        {
                            this.state.data.genre && this.state.data.genre.map((g, gidx) => {
                                return <div key={`genre-${gidx}`} className='Node-Item'>{g}</div>
                            })
                        }
                    </div>
                    {this.state.data.address && <div className='Node-Row Node-Flex'>
                        <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                        <div className='Node-Addr'>
                            {getAddress(this.state.data.address)}
                        </div>
                    </div>}
                    {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>{this.state.node} Contact Information</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 ? this.state.data.communicationArray.map((comm, cidx) => {
                        return <div key={`comm-${cidx}`} className='Node-Row Node-Flex My-Link'><div style={{ textDecoration: 'none' }} className='My-Link-Text'>{comm.communicationType === 'Phone' ? <FaPhone /> : (comm.communicationType === 'Fax' ? <FaFax /> : <MdEmail />)} {comm.communicationType === 'Email' ? <a href={`mailto:${comm.contact}`}>{comm.contact}</a> : (comm.communicationType === 'Phone' ? <a href={`tel:${comm.contact.replace(/[\D\s]/g, '')}`}>{comm.contact}</a> : comm.contact)} {comm.consent ? <MdPublic /> : <MdPublicOff />}</div></div>
                    }) : null}
                    {this.state.data.operationArray && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><LuCalendarClock /></div>
                        <div className='Node-Divider-Text'>Hours of Operation</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.operationArray && <div className='Node-Row Node-Flex-Left'>
                        {this.getOpHours()}
                    </div>}
                    {this.state.data.blackoutArray && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><LuCalendarX2 /></div>
                        <div className='Node-Divider-Text'>Blackout Dates</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.blackoutArray && <div className='Node-Row Node-Flex-Wrap'>
                        {this.state.data.blackoutArray.map((ba, baidx) => {
                            return <div key={`boa-${baidx}`} className="Node-Item">{moment(ba).format('ll')}</div>
                        })}
                    </div>}
                    {this.state.data.rate && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><MdOutlineAttachMoney /></div>
                        <div className='Node-Divider-Text'>Preferred Rate</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.rate && <div className='Node-Row Node-Flex-Wrap'>
                        <div className='Node-Divider-Text'>{this.state.data.rate.rateValue}</div>
                        <div className='Node-Addr-Icon Node-Divider-Text'>Negotiable: {this.state.data.rate.negotiable ? <FaRegCheckSquare /> : <FaSquareXmark />}</div>
                    </div>}
                    {this.state.data.description && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Description</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.description && <div className='Node-Row'>
                        <div className='Node-Details'>{this.state.data.description}</div>
                    </div>}
                    {this.getLinks()}
                    {this.state.node === 'Venue' && this.state.data.amenities && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Amenities</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.node === 'Venue' && this.state.data.amenities && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.data.amenities.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Restrictions</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.data.ages ? <div className='Node-Item'>{this.state.data.ages}</div> : null
                        }
                        {
                            this.state.data.restrictions.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.data.followers && this.state.data.followers > 0 ? <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Followers</div>
                        <div className='Node-Divider'></div>
                        <div>
                            <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                            <div className='Node-Heart-Text'>{this.state.data.followers > 1000 ? `${(this.state.data.followers / 1000).toFixed(1)}k` : this.state.data.followers}</div>
                        </div>
                    </div> : null}
                    {this.state.data.stars && this.state.data.stars > 0 ? <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Rating</div>
                        <div className='Node-Divider'></div>
                        <div style={{ marginRight: '5px' }}>
                            <div className='Node-Stars'><FaStar /></div>
                            <div className='Node-Stars-Text'>{this.state.data.stars.toFixed(1)}</div>
                        </div>
                    </div> : null}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text Node-Flex' onClick={() => this.handleAddPost()}>Posts from {this.state.data.name} <div style={{ marginLeft: '5px' }}><BiMessageAdd /></div></div>
                        <div className='Node-Divider'></div>
                        {this.state.data.posts && this.state.data.posts.length > 0 && <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>}
                    </div>
                    {this.state.displayPost && <div className='Rate-Container'>
                        <form onSubmit={this.handleSavePost}>
                            <div className='Node-Row'><Form.Group controlId="post" className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Post</Form.Label>
                                <Form.Control
                                    placeholder="New Post"
                                    as="textarea"
                                    rows="5"
                                    value={this.state.post}
                                    onChange={event => this.handleChange(event)}
                                />
                            </Form.Group></div>
                            <div className='Node-Row Node-Flex-Spread' style={{ marginTop: '30px' }}>
                                <div style={{ width: '160px', height: '60px' }}>
                                    <LoaderButton
                                        size="lg"
                                        disabled={!this.postIsValid()}
                                        type="submit"
                                        isLoading={this.state.isSaving}
                                        text='Post'
                                        loadingText='Saving...'
                                        className='Signup-Button'
                                        style={{ margin: '0px', bottom: '0' }}
                                    />
                                </div>
                                <div style={{ width: '160px', fontSize: '18pt', fontWeight: 'bold' }} className='Pref-Cancel-Button' onClick={() => this.handleCancelPost()}>Cancel</div>
                            </div>
                        </form>
                    </div>}
                    {this.state.data.posts && this.state.data.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                        {this.state.data.posts.sort((a, b) => {
                            if (moment(a.created).isAfter(moment(b.created))) return -1
                            if (moment(a.created).isBefore(moment(b.created))) return 1
                            return 0
                        }).map((post, pidx) => {
                            return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                <div className='Node-Row Node-Flex'>
                                    <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                    <div className='My-Review-Text'>{post.post}</div>
                                    <div>
                                        <div style={{ width: '75px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                        <div className='Post-Delete-Icon' onClick={() => this.handleDelPost(post.id)}><MdDeleteForever /></div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div> : null}
                    {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Reviews</div>
                        <div className='Node-Divider'></div>
                        <div className='Node-Divider-Button' onClick={() => this.handleMore('reviews')}>{this.state.more === 'reviews' ? 'Less' : 'More'}</div>
                    </div> : null}
                    {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'reviews' ? ' My-Reviews-More' : ''}`}>
                        {this.state.data.reviews.sort((a, b) => {
                            if (moment(a.created).isAfter(moment(b.created))) return -1
                            if (moment(a.created).isBefore(moment(b.created))) return 1
                            return 0
                        }).map((rev, ridx) => {
                            return <div key={`mrev-${ridx}`} className='My-Review-Container'>
                                <div className='My-Review-Stars'>
                                    {this.getSelectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                        return <div key={`ss-${ss}`} className='Node-Stars'><FaStar /></div>
                                    })}
                                    {this.getUnselectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                        return <div key={`us-${ss}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                    })}
                                </div>
                                <div className='Node-Row Node-Flex'>
                                    <div style={{ marginRight: '10px' }}>{rev.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${rev.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                    <div className='My-Review-Text'>{rev.comments}</div>
                                    <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(rev.created).format('ll')}</div>
                                </div>
                            </div>
                        })}
                    </div> : null}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Calendar of Events</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <Calendar
                        month={this.state.month}
                        onDaySelected={this.handleDayChanged}
                        onMonthChanged={this.handleMonthChanged}
                        onMonthSelected={this.handleMonthSelected}
                        selected={this.state.selDay}
                        days={this.state.days}
                        altDays={this.state.altDays} />
                    {this.state.data.events && this.state.selDay ? <div>
                        <div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>{moment(this.state.month).date(this.state.selDay).format('ll')}</div>
                            <div className='Node-Divider'></div>
                        </div>
                        <div className='Node-Row Node-Flex-Wrap'>
                            {[...this.state.data.events, ...this.state.interested, ...this.state.accepted].filter(e => moment(this.state.month).date(this.state.selDay).isSameOrBefore(moment(e.stopTime)) && moment(this.state.month).date(this.state.selDay + 1).isSameOrAfter(e.startTime)).map((event, eidx) => {
                                return <SmallCard key={`de-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                            })}</div>
                    </div> : null}
                    {this.state.node === 'Venue' && this.state.data.events && this.state.data.events.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Upcoming Events</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.events && this.state.data.events.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.data.events.filter(e => moment(e.stopTime).isAfter(moment())).map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                    })}</div>}
                    {this.state.data.accepted && this.state.data.accepted.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Upcoming Confirmed Gigs</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.accepted && this.state.data.accepted.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.data.accepted.filter(e => moment(e.stopTime).isAfter(moment())).map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleConfirmedSelect} />
                    })}</div>}
                    {this.state.data.interested && this.state.data.interested.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Upcoming Potential Gigs</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.interested && this.state.data.interested.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.data.interested.filter(e => moment(e.stopTime).isAfter(moment())).map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleConfirmedSelect} />
                    })}</div>}
                    {this.state.data.events && this.state.data.events.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Recent Events</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.events && this.state.data.events.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.data.events.filter(e => moment(e.stopTime).isBefore(moment())).map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                    })}</div>}
                    {this.state.data.accepted && this.state.data.accepted.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Recent Gigs</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.accepted && this.state.data.accepted.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.data.accepted.filter(e => moment(e.stopTime).isBefore(moment())).map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                    })}</div>}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Authorized Users</div>
                        <div className='Node-Divider'></div>
                    </div>
                    {this.state.data.createdby && this.state.data.createdby.email && <div className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{this.state.data.createdby.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${this.state.data.createdby.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{this.state.data.createdby.email}</div></div>}
                    {this.state.data.authorizedArray && this.state.data.authorizedArray.map((auth, auidx) => {
                        return <div key={`authu-${auidx}`} className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{auth.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${auth.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{auth.email}</div></div>
                    })}
                    {this.state.data.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/venue/${this.state.data.id}`} /></div>}
                    {this.state.data.doc && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Proof of Ownership/Management</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.data.doc ? <div className='Node-Row Node-Flex-Wrap My-Link'><a href={this.state.data.doc.docUrl ? this.state.data.doc.docUrl : `https://localtalent-upload.s3.amazonaws.com/public/${this.state.data.doc}`} target="_blank" rel="noreferrer">Proof of Owership or Management</a></div> : null}
                </div>
            </div>
        </div> : null
    }

    getEdit() {
        return <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Pref-Back'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
            <div className='Node-Row Node-Form'>
                <form onSubmit={this.handleSubmit}>
                    {this.state.node !== 'Venue' && <div className='Node-Row'>
                        <Form.Group className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>Contributor Type *</Form.Label>
                        </Form.Group>
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.node} id='node' onChange={this.handleChange}>
                            <option value=''>Select...</option>
                            {
                                this.state.options && this.state.options.filter(op => op.ptype !== 'Venue' && !op.ineligible).map((o, oidx) => {
                                    return <option key={`nt-${oidx}`} value={o.ptype}>{o.ptype}</option>
                                })
                            }
                        </Form.Select></div>}
                    <Form.Group controlId="name" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node === 'Venue' ? 'Venue' : 'Contributor'} Name *</Form.Label>
                        <Form.Control
                            placeholder="Name"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleChange}
                            isValid={this.getValidation('name')}
                            isInvalid={!this.getValidation('name')}
                        />
                    </Form.Group>
                    <Form.Group controlId="description" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node === 'Venue' ? 'Venue' : 'Contributor'} Description *</Form.Label>
                        <Form.Control
                            placeholder="Description"
                            as="textarea"
                            value={this.state.description}
                            rows={3}
                            onChange={this.handleChange}
                            isValid={this.getValidation('description')}
                            isInvalid={!this.getValidation('description')}
                        />
                    </Form.Group>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Category *</Form.Label>
                    </Form.Group>
                    <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', width: '100%', marginBottom: '5px', padding: '5px' }} value={this.state.category} id='category' onChange={this.handleChange}>
                        <option value=''>Select...</option>
                        {
                            this.state.options && this.state.node.length > 0 && this.state.options.filter(o => o.ptype === this.state.node)[0].categories.map((cat, cidx) => {
                                return <option key={`cat-${cidx}`} value={Object.keys(cat)[0]}>{Object.keys(cat)[0]}</option>
                            })
                        }
                    </Form.Select>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Genre</Form.Label>
                    </Form.Group><div className='Node-Flex-Wrap'>
                        {
                            this.state.genres.map((g, gidx) => {
                                return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`genre-${gidx}`} type='checkbox' id={g} label={g} checked={this.getOpt('genre', g)} onChange={() => this.handleOptionsChange('genre', g)} />
                            })
                        }</div>
                    <Form.Group controlId="loc" className="form-group-lg">
                        <Form.Label className='Pref-Label'>Address<span style={{ marginLeft: '8px' }} onClick={() => this.handleGetMyLoc()}><FaLocationArrow /></span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Search Address"
                            value={this.state.loc}
                            onChange={event => this.handleLocChanged(event)}
                            isValid={this.state.locIsGood}
                            isInvalid={!this.state.locIsGood}
                        />
                    </Form.Group>
                    <div className='Node-Row'>
                        <div className='Node-Flex'>
                            <div className='Pref-Label Pref-Link' onClick={() => this.handleLocationCheck(this.state.loc)}>Check Address{this.state.locIsGood && <span style={{ marginLeft: '5px' }}><img src={Checkmark} alt="Verified" className='Search-Location-Checkmark' /></span>}</div>
                            {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                            {this.state.info[0].displayMsg && this.getInfo(0)}
                        </div>
                    </div>
                    <Form.Group controlId="line1" className="form-group-lg Signup-Group">
                        <Form.Label className='Pref-Label'>Line 1 *</Form.Label>
                        <Form.Control
                            placeholder="Street Address Line 1"
                            type="text"
                            value={this.state.line1}
                            onChange={this.handleChange}
                            isValid={this.getValidation('line1')}
                            isInvalid={!this.getValidation('line1')}
                        />
                    </Form.Group>
                    <Form.Group controlId="line2" className="form-group-lg Signup-Group">
                        <Form.Label className='Pref-Label'>Line 2</Form.Label>
                        <Form.Control
                            placeholder="Address Line 2"
                            type="text"
                            value={this.state.line2}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="city" className="form-group-lg Signup-Group">
                        <Form.Label className='Pref-Label'>City *</Form.Label>
                        <Form.Control
                            placeholder="City"
                            type="text"
                            value={this.state.city}
                            onChange={this.handleChange}
                            isValid={this.getValidation('city')}
                            isInvalid={!this.getValidation('city')}
                        />
                    </Form.Group>
                    <Form.Group controlId="state" className="form-group-lg Signup-Group">
                        <Form.Label className='Pref-Label'>State *</Form.Label>
                        <Form.Control
                            placeholder="State"
                            type="text"
                            value={this.state.state}
                            onChange={this.handleChange}
                            isValid={this.getValidation('state')}
                            isInvalid={!this.getValidation('state')}
                        />
                    </Form.Group>
                    <Form.Group controlId="postalcode" className="form-group-lg Signup-Group">
                        <Form.Label className='Pref-Label'>Zip Code *</Form.Label>
                        <Form.Control
                            placeholder="Zip Code (#####)"
                            type="text"
                            value={this.state.postalcode}
                            onChange={this.handleChange}
                            isValid={this.getValidation('postalcode')}
                            isInvalid={!this.getValidation('postalcode')}
                        />
                    </Form.Group>
                    {
                        this.state.node === 'Venue' && this.state.options && <div className='Node-Row'>
                            <Form.Group className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Venue Amenities</Form.Label>
                            </Form.Group><div className='Node-Flex-Left'>
                                {
                                    (this.state.options.filter(o => o.ptype === 'Venue')[0].amenities || []).map((a, aidx) => {
                                        return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`amenity-${aidx}`} type='checkbox' id={a} label={a} checked={this.getOpt('amenities', a)} onChange={() => this.handleOptionsChange('amenities', a)} />
                                    })
                                }</div>
                            <Form.Group className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Venue Restrictions</Form.Label>
                            </Form.Group><div className='Node-Row Node-Flex-Left'>
                                {
                                    (this.state.options.filter(o => o.ptype === 'Venue')[0].restrictions || []).map((r, ridx) => {
                                        return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`restrict-${ridx}`} type='checkbox' id={r} label={r} checked={this.getOpt('restrictions', r)} onChange={() => this.handleOptionsChange('restrictions', r)} />
                                    })
                                }</div>
                        </div>
                    }
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Images</Form.Label>
                    </Form.Group>
                    <div className='Node-Row Node-Flex-Wrap'>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(0)}>
                            {
                                this.state.imageArray[0] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[0].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 0)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '0' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(1)}>
                            {
                                this.state.imageArray[1] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[1].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 1)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '1' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(2)}>
                            {
                                this.state.imageArray[2] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[2].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 2)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '2' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(3)}>
                            {
                                this.state.imageArray[3] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[3].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 3)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '3' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                    </div>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Proof of Management/Ownership</Form.Label>
                    </Form.Group>
                    <div className='Node-Row Node-Flex-Wrap'>
                        <div className='My-Img-Container'>
                            {
                                this.state.doc ? (this.state.doc.file.type.indexOf('image/') > -1 ? <img onClick={() => this.handleClearDoc()} className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.doc.docUrl} alt='' /> :
                                    <div style={{ paddingTop: '60px', color: 'green' }} onClick={() => this.handleClearDoc()}><FaFileCircleCheck /></div>) :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleDocChange(event)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === 'doc' ? <span><MdImageNotSupported />Size</span> : (this.state.badDoc ? <span><TbNotesOff />Type</span> : <FaFileCirclePlus />)}</div>
                                    </label>
                            }
                        </div>
                    </div>
                    <Form.Group controlId="link" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Links & Social Media</Form.Label>
                        <div className='Node-Row Node-Flex'>
                            <Form.Control
                                placeholder="URL"
                                type="text"
                                value={this.state.link}
                                onChange={this.handleChange}
                                isValid={this.getValidation('link')}
                            />
                            <div className='My-Link-Icon' onClick={() => this.handleAddLink()}>{this.getSocialIcon()}</div>
                        </div>
                    </Form.Group>
                    {
                        this.state.linkArray.map((link, lidx) => {
                            return <div key={`link-${lidx}`} className='Node-Row Node-Flex My-Link'><div className='My-Link-Icon'>{this.getSocialIcon(link)}</div><a className='My-Link-Text' href={link}>{link}</a><div className='My-Link-Icon' onClick={() => this.handleRemoveArray('linkArray', lidx)}><AiFillCloseCircle /></div></div>
                        })
                    }
                    <Form.Group controlId="contact" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Contact Information</Form.Label>
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', width: '100%', marginBottom: '5px', padding: '5px' }} value={this.state.commType} id='commType' onChange={this.handleChange}>
                            <option value='Phone'>Phone</option>
                            <option value='Email'>Email</option>
                            <option value='Fax'>Fax</option>
                        </Form.Select>
                        <div className='Node-Row Node-Flex'>
                            <Form.Control
                                placeholder={this.state.commType}
                                type="text"
                                value={this.state.contact}
                                onChange={this.handleChange}
                                isValid={this.getValidation(this.state.commType === 'email' ? 'email' : 'phone')}
                            />
                            <div className='My-Link-Icon' onClick={() => this.handleAddCommunication()}><FaPlusSquare /></div>
                        </div>
                        <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} type='checkbox' id='commConsent' label='Public' checked={this.state.commConsent} onChange={() => this.handleOptionChange('commConsent')} />
                    </Form.Group>
                    {
                        this.state.communicationArray.map((comm, cidx) => {
                            return <div key={`comm-${cidx}`} className='Node-Row Node-Flex My-Link'><div style={{ textDecoration: 'none' }} className='My-Link-Text'>{comm.communicationType === 'Phone' ? <FaPhone /> : (comm.communicationType === 'Fax' ? <FaFax /> : <MdEmail />)} {comm.contact} {comm.consent ? <MdPublic /> : <MdPublicOff />}</div><div className='My-Link-Icon' onClick={() => this.handleRemoveArray('communicationArray', cidx)}><AiFillCloseCircle /></div></div>
                        })
                    }
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Operating Hours</Form.Label>
                    </Form.Group><div className='Node-Flex-Left'>
                        {
                            ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((d, didx) => {
                                return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`opday-${didx}`} type='checkbox' id={d} label={d} checked={this.getOpt('days', d)} onChange={() => this.handleOptionsChange('days', d)} />
                            })
                        }
                    </div>
                    <div className='Node-Row Node-Flex'>
                        <div style={{ width: '200px' }} className='Node-Flex-Wrap'>
                            <Form.Group controlId="start" className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>{this.state.node === 'Venue' ? 'Open' : 'Start'} Time</Form.Label>
                                <Form.Control
                                    placeholder="Start Time"
                                    type="time"
                                    value={this.state.start}
                                    onChange={this.handleChange}
                                    isValid={this.getValidation('start')}
                                />
                            </Form.Group>
                            <Form.Group controlId="stop" style={{ marginLeft: '5px' }} className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>{this.state.node === 'Venue' ? 'Close' : 'Stop'} Time</Form.Label>
                                <Form.Control
                                    placeholder="Stop Time"
                                    type="time"
                                    value={this.state.stop}
                                    onChange={this.handleChange}
                                    isValid={this.getValidation('stop')}
                                />
                            </Form.Group>
                        </div>
                        <div className='Node-Flex-Wrap'>
                            <div style={{ width: '100%', height: '80px' }}></div>
                            <div className='Node-Close-Container' onClick={() => this.handleAddClosed()}>
                                <div className='Node-Close-Icon'><TbClockX /></div>
                                <div style={{ width: '100%', textAlign: 'center' }} className='Event-Icon-Text'>Closed</div>
                            </div>
                            <div style={{ marginTop: '20px' }} className='My-Link-Icon' onClick={() => this.handleAddOpHours()}><FaPlusSquare /></div>
                        </div>
                    </div>
                    {
                        this.state.operationArray.map((op, oidx) => {
                            return <div key={`op-${oidx}`} className='Node-Row Node-Flex-Spread'><div className='Node-Flex'><div className='Node-Flex-Wrap'>
                                {
                                    op.values.map((day, didx) => {
                                        return <div className='My-Time-Day' key={`opd-${didx}`}>{day}</div>
                                    })
                                }
                                <div className='My-Time'>{op.start ? `${moment(`2023-01-01 ${op.start}`).format('h:mm A')} - ${moment(`2023-01-01 ${op.stop}`).format('h:mm A')}` : 'Closed'}</div>
                            </div></div>
                                <div className='My-Link-Icon' onClick={() => this.handleRemoveArray('operationArray', oidx)}><AiFillCloseCircle /></div>
                            </div>
                        })
                    }
                    <Form.Group controlId="blackout" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Blackout Dates</Form.Label>
                        <div className='Node-Row Node-Flex'>
                            <Form.Control
                                placeholder="Blackout Date"
                                type="date"
                                value={this.state.blackout}
                                onChange={this.handleChange}
                                isValid={this.getValidation('blackout')}
                            />
                            <div className='My-Link-Icon' onClick={() => this.handleAddBlackout()}><FaPlusSquare /></div>
                        </div>
                    </Form.Group>
                    {this.state.blackoutArray && <div className='Node-Row Node-Flex-Wrap'>
                        {this.state.blackoutArray.map((ba, baidx) => {
                            return <div key={`boa-${baidx}`} className="Node-Item Node-Flex-Spread" style={{ borderColor: '#fff' }}>
                                <div className='My-Time'>{moment(ba).format('ll')}</div>
                                <div className='My-Link-Icon' onClick={() => this.handleRemoveArray('blackoutArray', baidx)}><AiFillCloseCircle /></div>
                            </div>
                        })}
                    </div>}
                    {
                        this.state.node !== 'Venue' && <div className='Node-Row'>
                            <Form.Group controlId="rate" style={{ marginLeft: '5px' }} className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>{this.state.node} Rate</Form.Label>
                                <Form.Control
                                    placeholder="Approx. Rate"
                                    type="text"
                                    value={this.state.rate}
                                    onChange={this.handleChange}
                                    isValid={this.getValidation('rate')}
                                />
                            </Form.Group>
                            <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} type='checkbox' id='negotiable' label='Negotiable?' checked={this.state.negotiable} onChange={() => this.handleOptionChange('negotiable')} />
                        </div>
                    }
                    <Form.Group controlId="authorized" style={{ marginLeft: '5px' }} className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{this.state.node} Authorized Users</Form.Label>
                        <div className='Node-Flex'>
                            <Form.Control
                                placeholder="Gigloca User Email"
                                type="email"
                                value={this.state.authorized}
                                onChange={this.handleChange}
                                isValid={this.getValidation('authorized')}
                            />
                            <div className='My-Link-Icon' onClick={() => this.handleAddAuth()}><FaPlusSquare /></div>
                            {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : null}
                        </div>
                    </Form.Group>
                    {
                        this.state.authorizedArray.map((auth, auidx) => {
                            return <div key={`authu-${auidx}`} className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{auth.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${auth.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{auth.email}</div><div className='My-Link-Icon' onClick={() => this.handleRemoveArray('authorizedArray', auidx)}><AiFillCloseCircle /></div></div>
                        })
                    }
                    <div className='Node-Row'>
                        <LoaderButton
                            size="lg"
                            disabled={!this.formIsValid()}
                            type="submit"
                            isLoading={this.state.isSaving}
                            text={this.state.nid ? 'Update' : 'Submit'}
                            loadingText={this.state.nid ? 'Updating...' : 'Submitting...'}
                            className='Signup-Button'
                        />
                        <div className='Node-Row Pref-Cancel-Button' onClick={() => this.handleCancel()}>Cancel</div>
                    </div>
                </form>
            </div>
        </div>
    }

    render() {
        //console.log(this.state)
        return <div className={`My-Container Node-${this.props.mode}`}>
            <TopMenu
                mode={this.props.mode}
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {
                this.state.splash ? this.getSplash() : (this.state.bounce ? this.getBounce() : (this.state.view === 'edit' ? this.getEdit() : this.getView()))
            }
            {
                this.state.displayMsg && this.getMessage()
            }
            <BottomMenu
                menu={this.state.menu}
                onLeft={this.handleLeftMenu}
                onGiglit={this.handleGiglit}
                onRight={this.handleRightMenu} />
        </div>
    }
}

export default withRouter(MyNode)