import { Component } from 'react'
import { API, Storage } from 'aws-amplify'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import SmallCard from '../components/smallCard'
import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'
import Carousel from 'react-bootstrap/Carousel'
import LoaderButton from '../components/loaderButton'
import moment from 'moment'
import QRCode from 'qrcode.react'
import { v1 as uuidv1 } from 'uuid'
import Logo from '../images/GigLoca_Logo_Light.png'
import withRouter from '../components/withRouter'
import { MdAddAPhoto, MdDeleteForever, MdPersonSearch, MdImageNotSupported, MdOutlineVerified, MdOutlineCreditCardOff, MdSecurityUpdateGood } from 'react-icons/md'
import { FaArrowLeft, FaHeart, FaMapMarkedAlt, FaUser, FaClipboardCheck, FaClipboardList, FaRegClipboard, FaTimesCircle, FaTheaterMasks, FaPlusSquare, FaUserAltSlash } from 'react-icons/fa'
import { BsCalendarFill, BsClockHistory, BsInfoCircle, BsQrCodeScan } from 'react-icons/bs'
import { BiMessageAdd } from 'react-icons/bi'
import { GiPartyPopper, GiSoundOn, GiDrumKit, GiMusicalNotes, GiLoveSong, GiJuggler, GiVikingLonghouse, GiTicket } from 'react-icons/gi'
import { GoUnverified } from 'react-icons/go'
import { SiDiscogs, SiSnapcraft } from 'react-icons/si'
import { PiGuitarThin, PiTelevisionThin } from 'react-icons/pi'
import { FaPeopleGroup, FaPersonBooth, FaKitchenSet } from 'react-icons/fa6'
import { IoPeople } from 'react-icons/io5'
import { RiQuestionAnswerLine, RiSecurePaymentFill } from 'react-icons/ri'
import { HiPaintBrush } from 'react-icons/hi2'
import { AiOutlinePicture, AiFillCloseCircle } from 'react-icons/ai'
import { LuCopyPlus } from 'react-icons/lu'
import { TbTicketOff } from 'react-icons/tb'
import { getAddress, temp } from '../helpers'
import './node.css'

class MyEvent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            menu: {
                left: 'dashboard',
                right: 'edit',
                action: 'addEvent'
            },
            splash: true,
            bounce: false,
            more: null,
            venue: null,
            event: null,
            eventId: null,
            slot: null,
            isSaving: false,
            displayPost: false,
            view: 'show',
            title: '',
            summary: '',
            details: '',
            start: '',
            end: '',
            node: '',
            category: '',
            description: '',
            prebooked: false,
            options: null,
            aOptions: [],
            rOptions: [],
            slots: [],
            amenities: [],
            restrictions: [],
            imageArray: [],
            summaryOpt: [],
            post: '',
            nodes: [],
            displayConfirm: false,
            displayConfirmSubmit: false,
            confirmMsg: '',
            confirmType: '',
            nid: null,
            msg: '',
            findNodes: [],
            imageSize: '',
            displayMsg: false,
            slotNodes: null,
            notified: [],
            id: null,
            tixCats: [],
            tix: null,
            hasTix: false,
            tixCat: '',
            tixCount: '',
            tixRate: '',
            tixLabel: '',
            tixPay: false,
            tixEmail: false,
            tixLimit: '',
            security: '',
            securityArray: [],
            info: [
                { id: 0, displayInfo: false, displayMsg: false, msg: 'Gigloca will accept customer payment as per our Payment Service Policy.', dir: 'left', val: '60px' },
                { id: 1, displayInfo: false, displayMsg: false, msg: 'Gigloca will email ticket holders a security code to verify authenticity of tickets.', dir: 'left', val: '60px' }
            ]
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
        this.handleRightMenu = this.handleRightMenu.bind(this)
        this.handleNodeSelect = this.handleNodeSelect.bind(this)
        this.handleAccept = this.handleAccept.bind(this)
        this.handleRequestInterest = this.handleRequestInterest.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            const { path, search } = this.props
            const id = path.id
            const queryParams = new URLSearchParams(search.search)
            const eventId = queryParams.get('event')
            this.props.setMode('Venue')
            const idx = this.props.myNodes && this.props.myNodes.Venue ? this.props.myNodes.Venue.findIndex(v => v.id === id) : -1
            if (idx > -1) {
                if (eventId === 'new') {
                    API.get('lt', 'options?prop=node')
                        .then(resp => {
                            let summaryOpt = []
                            resp.data.filter(op => op.ptype !== 'Venue' && op.fanSearch).forEach(n => {
                                summaryOpt = [...summaryOpt, ...n.categories.map(c => Object.keys(c)[0])]
                            })
                            const aOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].amenities
                            const rOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].restrictions
                            this.setState({
                                options: resp.data,
                                view: 'edit',
                                splash: false,
                                menu: {
                                    left: 'dashboard',
                                    right: 'view',
                                    action: 'addEvent'
                                },
                                venue: {
                                    name: this.props.myNodes.Venue[idx].name,
                                    address: getAddress(this.props.myNodes.Venue[idx].address),
                                    amenities: this.props.myNodes.Venue[idx].amenities || [],
                                    restrictions: this.props.myNodes.Venue[idx].restrictions || [],
                                    coord: this.props.myNodes.Venue[idx].address.coord
                                },
                                id,
                                summaryOpt,
                                aOptions,
                                rOptions,
                                amenities: this.props.myNodes.Venue[idx].amenities || [],
                                restrictions: this.props.myNodes.Venue[idx].restrictions || []
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                        })
                } else {
                    this.setState({
                        menu: {
                            left: 'dashboard',
                            right: 'edit',
                            action: 'addEvent'
                        },
                        venue: {
                            name: this.props.myNodes.Venue[idx].name,
                            address: getAddress(this.props.myNodes.Venue[idx].address),
                            amenities: this.props.myNodes.Venue[idx].amenities || [],
                            restrictions: this.props.myNodes.Venue[idx].restrictions || [],
                            coord: this.props.myNodes.Venue[idx].address.coord
                        },
                        id,
                        view: 'show',
                        splash: false,
                        eventId,
                        hasTix: this.props.myNodes.Venue[idx].tix ? true : false
                    })
                    this.getEvent(eventId, id)
                }
            } else setTimeout(() => this.handleNavigate(`my/venues`), 150)
        } else {
            this.setState({ splash: false, bounce: true })
            setTimeout(() => {
                this.handleNavigate('main', true)
            }, 3000)
        }
    }

    handleNavigate(page, history) {
        const { search } = this.props
        if (history) this.props.onAddHistory(search.pathname + search.search)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack()
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile', true)
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else if (mode !== 'fan') {
            this.props.setMode(mode)
            this.handleNavigate(`my/${mode === 'Venue' ? 'venues' : 'contributors'}`, true)
        } else this.handleNavigate('', true)
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications', true)
    }

    handleLeftMenu() {
        this.handleNavigate('my/venues')
    }

    handleRightMenu() {
        if (this.state.options) {
            this.setState({
                menu: { ...this.state.menu, right: this.state.view === 'show' ? 'view' : 'edit' },
                view: this.state.view === 'show' ? 'edit' : 'show',
                event: {
                    ...this.state.event,
                    title: this.state.title || '',
                    summary: this.state.summary || '',
                    details: this.state.details || '',
                    startTime: this.state.start || '',
                    stopTime: this.state.end || '',
                    slots: this.state.slots || [],
                    amenities: this.state.amenities || [],
                    restrictions: this.state.restrictions || [],
                    imageArray: this.state.imageArray || []
                }
            })
        } else {
            API.get('lt', 'options?prop=node')
                .then(resp => {
                    let summaryOpt = []
                    resp.data.filter(op => op.ptype !== 'Venue' && op.fanSearch).forEach(n => {
                        summaryOpt = [...summaryOpt, ...n.categories.map(c => Object.keys(c)[0])]
                    })
                    const aOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].amenities
                    const rOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].restrictions
                    this.setState({
                        menu: {
                            left: 'dashboard',
                            right: 'view',
                            mode: null,
                            action: this.state.route === 'Venue' ? 'addEvent' : 'findGigs'
                        },
                        view: 'edit',
                        options: resp.data,
                        summaryOpt,
                        aOptions,
                        rOptions
                    })
                    this.handleReset()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleNodeSelect(nid) {
        this.handleNavigate(`contributor/${nid}`, true)
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleAddSecurity() {
        if (this.state.security.length > 7 && this.state.securityArray.findIndex(s => s.email === this.state.security) === -1) {
            API.get('lt', `email?email=${this.state.security}`)
                .then(resp => {
                    if (resp.data.verified) {
                        this.setState({ userFailed: false, securityArray: [...this.state.securityArray, resp.data], security: '' })
                    } else {
                        this.setState({ userFailed: true })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ userFailed: true })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else this.setState({ userFailed: true })
    }

    handleRemoveArray(type, idx) {
        let array = [...this.state[type]]
        array.splice(idx, 1)
        this.setState({ [type]: array })
    }

    handleReset() {
        if (this.state.event) {
            this.setState({
                event: this.state.data.events[0],
                title: this.state.data.events[0].title || '',
                summary: this.state.data.events[0].summary || '',
                details: this.state.data.events[0].details || '',
                start: this.state.data.events[0].startTime || '',
                end: this.state.data.events[0].stopTime || '',
                slots: this.state.data.events[0].slots || [],
                amenities: this.state.data.events[0].amenities || [],
                restrictions: this.state.data.events[0].restrictions || [],
                imageArray: this.state.data.events[0].imageArray ? this.state.data.events[0].imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                imageSize: ''
            })
        } else {
            this.handleNavigate(`my/venue/${this.state.id}`)
        }
    }

    handleCancel() {
        if (this.state.eventId) {
            this.setState({
                menu: {
                    left: 'dashboard',
                    right: 'edit',
                    action: 'addEvent'
                },
                view: 'show'
            })
            this.getEvent()
        } else this.handleNavigate(`my/${this.props.mode}s`)
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'title':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "").substring(0, 29) })
                break
            case 'node':
                this.setState({ [event.target.id]: event.target.value.replace(/[^A-Za-z ]/g, "") })
                break
            case 'category':
                this.setState({ [event.target.id]: event.target.value.replace(/[^A-Za-z ]/g, "") })
                break
            case 'tixCat':
                this.setState({ [event.target.id]: event.target.value.replace(/[^A-Za-z ]/g, "") })
                break
            case 'tixLabel':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "").substring(0, 99) })
                break
            case 'security':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "").toLowerCase() })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "") })
        }
    }

    handleOptionChange(opt) {
        this.setState({ [opt]: !this.state[opt] })
    }

    handleOptionsChange(type, o) {
        let opt = [...this.state[type]]
        const idx = opt.length > 0 ? opt.indexOf(o) : -1
        if (idx > -1) {
            opt.splice(idx, 1)
        } else opt.push(o)
        this.setState({ [type]: opt })
    }

    handleImageChange(e, idx) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let image = uuidv1() + file.type.replace('image/', '.')
                let imageArray = [...this.state.imageArray]
                imageArray.splice(idx, 1, { file, image, imgUrl: URL.createObjectURL(file) })
                this.setState({ imageArray, imageSize: '' })
            }
        } else this.setState({ imageSize: idx.toString() })

        this.fileInput.value = ''
    }

    handleClearPic(idx) {
        let imageArray = [...this.state.imageArray]
        imageArray.splice(idx, 1)
        this.setState({ imageArray, imageSize: '' })
    }

    handleAddPost() {
        this.setState({ displayPost: true })
    }

    handleCancelPost() {
        this.setState({ displayPost: false, post: '' })
    }

    handleSavePost = (event) => {
        event.preventDefault()
        if (this.state.post.length > 3) {
            this.setState({ isSaving: true })
            API.put('lt', `posts/event/${this.state.eventId}`, { body: { post: this.state.post, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ event: { ...this.state.event, posts: resp.event.posts }, data: { ...this.state.data, events: [{ ...this.state.event, posts: resp.event.posts }] }, displayPost: false, post: '', isSaving: false })
                })
                .catch(e => {
                    this.setState({ displayPost: false, post: '', isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        }
    }

    handleDelPost(pid) {
        API.del('lt', `posts/event/${this.state.eventId}/${pid}`)
            .then(resp => {
                this.setState({ event: { ...this.state.event, posts: resp.event.posts }, data: { ...this.state.data, events: [{ ...this.state.event, posts: resp.event.posts }] } })
            })
            .catch(e => {
                this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                console.log(e)
            })
    }

    handleFindSlotNodes(node, cat, slot) {
        //console.log(node, cat, slot)
        if (node && cat) {
            API.get('lt', `nodes?node=${node}&cat=${cat}&lat=${this.state.venue ? this.state.venue.coord.lat : this.state.event.coord.lat}&lng=${this.state.venue ? this.state.venue.coord.lng : this.state.event.coord.lng}&dist=${this.props.user.dist || '50'}`)
                .then(resp => {
                    if (resp.data && resp.data.length > 0) {
                        this.setState({ slotNodes: { [slot]: resp.data } })
                    } else this.setState({ slotNodes: null, msg: resp.msg || 'No matching contributors found in range of this event', displayMsg: true })
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    handleFindNodes() {
        if (this.state.eventId) {
            API.get('lt', `nodes?eid=${this.state.eventId}`)
                .then(resp => {
                    if (resp.data.length > 0) {
                        this.setState({ msg: 'The followng contributors have been notified of your event.', nodes: resp.data })
                    } else this.setState({ msg: 'No applicable contributors found.' })
                })
                .catch(e => {
                    this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        }
    }

    handleRequestInterest(nid, slot, name) {
        //console.log('handlerequestinterest', nid, slot, name)
        if (this.state.eventId) {
            if (this.state.notified.indexOf(nid) === -1) {
                API.put('lt', `interest/${this.state.eventId}`, { body: { id: nid, venue: this.state.id, slot: slot.idx, local: moment().format('YYYY-MM-DD HH:mm') } })
                    .then(resp => {
                        this.setState({ msg: `${name} has been solicited for this Event.`, displayMsg: true, notified: [...this.state.notified, nid] })
                    })
                    .catch(e => {
                        console.log(e)
                        this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true, displayConfirm: false })
                    })
            } else this.setState({ msg: `${name} has already been solicited for this Event`, displayMsg: true })
        } else this.setState({ msg: 'Interest may not be solicited until Event is Saved', displayMsg: true })
    }

    handleAccept(nid, slot, confirm) {
        let s = this.state.event.slots.filter(s => s.idx === slot)[0]
        if (s.selected && s.selected === nid) {
            //confirm unselect
            if (confirm) {
                API.put('lt', `accept/${this.state.eventId}`, { body: { nid, slot, local: moment().format('YYYY-MM-DD HH:mm') } })
                    .then(resp => {
                        this.getEvent()
                        this.setState({ nid: null, displayConfirm: false, confirmMsg: '', slot: null, confirmType: '' })
                    })
                    .catch(e => {
                        console.log(e)
                        this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true, displayConfirm: false })
                    })
            } else {
                this.setState({ nid, slot, displayConfirm: true, confirmMsg: 'Are you sure you want to revoke acceptance?', confirmType: 'accept' })
            }
        } else {
            //accept
            API.put('lt', `accept/${this.state.eventId}`, { body: { nid, slot, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.getEvent()
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                })
        }
    }

    handleConfirm(confirm) {
        if (confirm) {
            if (this.state.confirmType === 'accept') {
                this.handleAccept(this.state.nid, this.state.slot, confirm)
            } else if (this.state.confirmType === 'delete') {
                this.handleDeleteEvent(confirm)
            }
        } else this.setState({ displayConfirm: false, confirmMsg: '', confirmType: '' })
    }

    handleAddSlot() {
        if (this.state.node.length > 0 && this.state.category.length > 0) {
            let slots = [...this.state.slots, { node: this.state.node, category: this.state.category, description: this.state.description, prebooked: this.state.prebooked, open: !this.state.prebooked }]
            this.setState({ slots, node: '', category: '', description: '', prebooked: false })
        }
    }

    handleRemoveSlot(idx) {
        if (this.state.slots[idx].idx) {
            this.setState({ isSaving: true })
            API.del('lt', `event/${this.state.eventId}/${this.state.slots[idx].idx}`)
                .then(resp => this.getEvent())
                .catch(e => {
                    this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        } else {
            let slots = [...this.state.slots]
            slots.splice(idx, 1)
            this.setState({ slots, isSaving: false })
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ isSaving: true })
        const body = {
            title: this.state.title,
            summary: this.state.summary,
            details: this.state.details,
            startTime: this.state.start,
            stopTime: this.state.end,
            imageArray: this.state.imageArray.map(ia => ia.image || ia),
            amenities: this.state.amenities,
            restrictions: this.state.restrictions,
            slots: this.state.slots || null
        }
        if (this.state.tix) body.tix = { groups: [...this.state.tix], securityArray: this.state.securityArray, payment: this.state.tixPay, email: this.state.tixEmail }
        let iComp = 0
        let comp = false
        this.state.imageArray.filter(i => i.file).forEach(image => {
            Storage.put(`${image.image}`, image.file, {
                resumable: true,
                completeCallback: (event) => {
                    iComp += 1
                    console.log(`Successfully uploaded ${event.key}`)
                    if (comp && iComp === this.state.imageArray.filter(i => i.file).length) {
                        if (this.state.event && this.state.event.active) {
                            this.handleConfirmSubmit()
                        } else this.handleSubmitNotice()
                    }
                },
                progressCallback: (progress) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
                },
                errorCallback: (err) => {
                    console.error('Unexpected error while uploading', err)
                }
            })
        })

        if (this.state.eventId) {
            API.put('lt', `events/${this.state.eventId}`, { body })
                .then(resp => {
                    if (iComp === this.state.imageArray.filter(i => i.file).length) {
                        if (this.state.event && this.state.event.active) {
                            this.handleConfirmSubmit()
                        } else this.handleSubmitNotice()
                    } else comp = true
                })
                .catch(e => {
                    this.setState({ isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        } else {
            API.post('lt', `events`, { body: { ...body, venue: this.state.id } })
                .then(resp => {
                    if (iComp === this.state.imageArray.filter(i => i.file).length) {
                        this.handleSubmitNotice()
                    } else comp = true
                })
                .catch(e => {
                    this.setState({ isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        }
    }

    handleSubmitNotice() {
        this.setState({ displayConfirmSubmit: true })
    }

    handleConfirmSubmit() {
        this.handleNavigate(`my/venue/${this.state.id}`, true)
    }

    handleActivate() {
        if (this.state.eventId) {
            if (this.state.event.nodes && this.state.event.nodes.length > 0) {
                this.setState({ msg: "Events with accepted contributors may not be deactivated. Revoke acceptance or delete the event to proceed with this action.", displayMsg: true })
            } else {
                this.setState({ isSaving: true })
                API.put('lt', `events/${this.state.eventId}`, { body: { active: !this.state.event.active } })
                    .then(resp => {
                        this.setState({ event: { ...this.state.event, active: resp.event.active }, isSaving: false })
                    })
                    .catch(e => {
                        this.setState({ isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                        console.log(e)
                    })
            }
        }
    }

    handleDeleteEvent(confirm) {
        if (confirm) {
            API.del('lt', `event/${this.state.eventId}`)
                .then(resp => {
                    this.handleBack()
                })
                .catch(e => {
                    this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayConfirm: false, displayMsg: true })
                    console.log(e)
                })
        } else if (this.state.displayConfirm) {
            this.setState({ displayConfirm: false, confirmMsg: '', confirmType: '' })
        } else this.setState({ displayConfirm: true, confirmMsg: 'Are you sure you want to DELETE this Event?', confirmType: 'delete' })
    }

    //??? tix ???
    handleClone() {
        if (this.state.options) {
            this.setState({
                view: 'edit',
                splash: false,
                isSaving: false,
                displayPost: false,
                eventId: null,
                post: '',
                nodes: [],
                displayConfirm: false,
                confirmMsg: '',
                confirmType: '',
                nid: null,
                msg: '',
                findNodes: [],
                displayMsg: false,
                slotNodes: null,
                notified: [],
                node: '',
                category: '',
                description: '',
                prebooked: false,
                menu: {
                    left: 'dashboard',
                    right: 'view',
                    action: 'addEvent'
                },
                event: {
                    title: this.state.event.title || '',
                    summary: this.state.event.summary || '',
                    details: this.state.event.details || '',
                    startTime: '',
                    stopTime: '',
                    slots: this.state.event.slots ? this.state.event.slots.map(s => { return { node: s.node, category: s.category, description: s.description, prebooked: s.prebooked, open: !s.prebooked } }) : [],
                    amenities: this.state.event.amenities || [],
                    restrictions: this.state.event.restrictions || [],
                    imageArray: this.state.event.imageArray ? this.state.event.imageArray.map(i => { return i.imgUrl ? i : { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : []
                },
                title: this.state.event.title || '',
                summary: this.state.event.summary || '',
                details: this.state.event.details || '',
                start: '',
                end: '',
                slots: this.state.event.slots ? this.state.event.slots.map(s => { return { node: s.node, category: s.category, description: s.description, prebooked: s.prebooked, open: !s.prebooked } }) : [],
                amenities: this.state.event.amenities || [],
                restrictions: this.state.event.restrictions || [],
                imageArray: this.state.event.imageArray ? this.state.event.imageArray.map(i => { return i.imgUrl ? i : { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                imageSize: '',
                data: null,
                tix: this.state.event.tix || null
            })
            this.handleNavigate(`my/event/${this.state.id}?event=new`, false)
        } else {
            API.get('lt', 'options?prop=node')
                .then(resp => {
                    let summaryOpt = []
                    resp.data.filter(op => op.ptype !== 'Venue' && op.fanSearch).forEach(n => {
                        summaryOpt = [...summaryOpt, ...n.categories.map(c => Object.keys(c)[0])]
                    })
                    const aOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].amenities
                    const rOptions = resp.data.filter(opt => opt.ptype === 'Venue')[0].restrictions
                    this.setState({
                        options: resp.data,
                        summaryOpt,
                        aOptions,
                        rOptions,
                        view: 'edit',
                        splash: false,
                        isSaving: false,
                        displayPost: false,
                        eventId: null,
                        post: '',
                        nodes: [],
                        displayConfirm: false,
                        confirmMsg: '',
                        confirmType: '',
                        nid: null,
                        msg: '',
                        findNodes: [],
                        displayMsg: false,
                        slotNodes: null,
                        notified: [],
                        node: '',
                        category: '',
                        description: '',
                        prebooked: false,
                        menu: {
                            left: 'dashboard',
                            right: 'view',
                            action: 'addEvent'
                        },
                        event: {
                            title: this.state.event.title || '',
                            summary: this.state.event.summary || '',
                            details: this.state.event.details || '',
                            startTime: '',
                            endTime: '',
                            slots: this.state.event.slots ? this.state.event.slots.map(s => { return { node: s.node, category: s.category, description: s.description, prebooked: s.prebooked, open: !s.prebooked } }) : [],
                            amenities: this.state.event.amenities || [],
                            restrictions: this.state.event.restrictions || [],
                            imageArray: this.state.event.imageArray ? this.state.event.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : []
                        },
                        title: this.state.event.title || '',
                        summary: this.state.event.summary || '',
                        details: this.state.event.details || '',
                        start: '',
                        end: '',
                        slots: this.state.event.slots ? this.state.event.slots.map(s => { return { node: s.node, category: s.category, description: s.description, prebooked: s.prebooked, open: !s.prebooked } }) : [],
                        amenities: this.state.event.amenities || [],
                        restrictions: this.state.event.restrictions || [],
                        imageArray: this.state.event.imageArray ? this.state.event.imageArray.map(i => { return { image: i, imgUrl: `https://localtalent-upload.s3.amazonaws.com/public/${i}` } }) : [],
                        imageSize: '',
                        data: null,
                        tix: this.state.event.tix || null
                    })
                    this.handleNavigate(`my/event/${this.state.id}?event=new`, false)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false, isSaving: false })
    }

    handleTix() {
        if (this.state.tixCats.length === 0) {
            API.get('lt', 'options?prop=tix')
                .then(resp => {
                    this.setState({ tixCats: resp.data[0] ? resp.data[0].categories : [] })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        }
        if (this.state.id) {
            if (this.state.tix) {
                //???can you do this if the event is already created???
                this.setState({ tix: null })
            } else {
                this.setState({ tix: [], securityArray: this.state.securityArray.length > 0 ? this.state.securityArray : (this.props.user ? [{ email: this.props.user.email, image: this.props.user.image || null }] : []) })
            }
        } else {
            if (this.state.tix) {
                this.setState({ tix: null })
            } else {
                this.setState({ tix: [] })
            }
        }
    }

    handleAddTix() {
        if (this.tixFormIsValid()) {
            let tix = [...this.state.tix, { id: this.state.tix.length, cat: this.state.tixCat, count: this.state.tixCount, price: this.state.tixRate || 0, label: this.state.tixLabel.length > 0 ? this.state.tixLabel : `Group ${this.state.tix.length + 1}`, limit: this.state.tixLimit.length > 0 ? this.state.tixLimit : null }]
            this.setState({
                tix,
                tixCat: '',
                tixCount: '',
                tixRate: '',
                tixLabel: '',
                tixLimit: '',
                hasTix: true
            })
        }
    }

    handleRemoveTix(id) {
        if (this.state.tix) {
            let idx = this.state.tix.findIndex(t => t.id === id)
            let tix = [...this.state.tix]
            tix.splice(idx, 1)
            this.setState({ tix })
        }
    }

    handleTixPay() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: !this.state.tixPay, displayMsg: false })
        this.setState({ tixPay: !this.state.tixPay, info })
    }

    handleTixEmail() {
        let info = [...this.state.info]
        info.splice(1, 1, { ...this.state.info[1], displayInfo: !this.state.tixEmail, displayMsg: false })
        this.setState({ tixEmail: !this.state.tixEmail, info })
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[id], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    formIsValid() {
        //finish this
        return (
            this.state.title.length > 0 &&
            this.state.details.length > 0 &&
            this.state.summary.length > 0 &&
            moment(this.state.start).isValid() &&
            moment(this.state.start).isAfter(moment()) &&
            moment(this.state.end).isValid() &&
            moment(this.state.end).isAfter(moment(this.state.start))
        )
    }

    tixFormIsValid() {
        return (
            this.state.tixCat.length > 0 &&
            (/^\d+$/).test(this.state.tixCount) && parseInt(this.state.tixCount) > 0 &&
            (/^\d+.\d{2}$/).test(this.state.tixRate) && parseFloat(this.state.tixRate) >= 0 &&
            (this.state.tixLimit === '' || ((/^\d+$/).test(this.state.tixLimit) && parseInt(this.state.tixLimit) > 0))
        )
    }

    postIsValid() {
        return this.state.post.length > 3
    }

    getEvent(eventId, id) {
        API.get('lt', `fanevent/${eventId || this.state.eventId}`)
            .then(resp => {
                this.setState({
                    event: resp.data.events[0],
                    data: resp.data,
                    slots: resp.data.events[0].slots || [],
                    amenities: resp.data.events[0].amentities || [],
                    restrictions: resp.data.events[0].restrictions || [],
                    summary: resp.data.events[0].summary || '',
                    imageArray: resp.data.events[0].imageArray || [],
                    tix: resp.data.events[0].tix && resp.data.events[0].tix.groups ? resp.data.events[0].tix.groups : null,
                    tixPay: resp.data.events[0].tix && resp.data.events[0].tix.payment,
                    tixEmail: resp.data.events[0].tix && resp.data.events[0].tix.email,
                    securityArray: resp.data.events[0].tix && resp.data.events[0].tix.securityArray ? resp.data.events[0].tix.securityArray : [],
                    isSaving: false
                })
                if (resp.data.events[0].tix) {
                    this.getTixOptions()
                }
            })
            .catch(err => {
                console.log(err)
                this.handleNavigate(`my/venue/${id || this.state.id}`)
            })
    }

    getTixOptions() {
        API.get('lt', 'options?prop=tix')
            .then(resp => {
                this.setState({ tixCats: resp.data[0] ? resp.data[0].categories : [] })
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.status && err.response.status === 403) {
                    this.handleNavigate('main')
                } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
            })
    }

    getValidation(field) {
        //!!! finish this !!!
        switch (field) {
            case 'title':
                return this.state[field].length > 1
            case 'details':
                return this.state[field].length > 1
            case 'summary':
                return this.state[field].length > 1
            case 'start':
                return this.state[field].length > 0 && moment(this.state[field]).isValid() && moment(this.state[field]).isAfter(moment())
            case 'end':
                return this.state[field].length > 0 && moment(this.state[field]).isValid() && moment(this.state[field]).isAfter(moment()) && this.state.start.length > 0 && moment(this.state.start).isValid && moment(this.state[field]).isAfter(moment(this.state.start))
            case 'description':
                return this.state[field].length > 3
            case 'tixCount':
                return (/^\d+$/).test(this.state[field]) && parseInt(this.state[field]) > 0
            case 'tixLimit':
                return this.state[field] === '' || ((/^\d+$/).test(this.state[field]) && parseInt(this.state[field]) > 0)
            case 'tixRate':
                return (/^\d+.\d{2}$/).test(this.state[field])
            default:
                return false
        }
    }

    getIcon(cat) {
        switch (cat) {
            case 'Acoustics Band':
                return <GiSoundOn />
            case 'DJ':
                return <SiDiscogs />
            case 'Band':
                return <GiDrumKit />
            case 'Solo Artist':
                return <PiGuitarThin />
            case 'Tribute Band':
                return <GiMusicalNotes />
            case 'Group':
                return <FaPeopleGroup />
            case 'Duet':
                return <IoPeople />
            case 'Comedian':
                return <FaTheaterMasks />
            case 'Karaoke':
                return <GiLoveSong />
            case 'Trivia':
                return <RiQuestionAnswerLine />
            case 'Performer':
                return <GiJuggler />
            case 'Booth':
                return <FaPersonBooth />
            case 'Paint':
                return <HiPaintBrush />
            case 'Bounce House':
                return <GiVikingLonghouse />
            case 'Art':
                return <AiOutlinePicture />
            case 'Crafting':
                return <SiSnapcraft />
            case 'Tasting':
                return <FaKitchenSet />
            case 'Watch Party':
                return <PiTelevisionThin />
            default:
                return <GiPartyPopper />
        }
    }

    getOpt(type, o) {
        let idx = this.state[type].indexOf(o)
        return idx > -1
    }

    getSplash() {
        return <div className='My-Splash'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
        </div>
    }

    getBounce() {
        return <div className='Pref-Bounce'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Node-Content'>Become part of the fun by Logging In or Joining GigLoca.</div>
        </div>
    }

    getConfirm() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.confirmMsg}</div>
            <div className="Node-Row Node-Flex-Spread">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleConfirm(true, 'interest')}>Yes</div>
                <div className='Pref-Button Pref-Button-Cancel' onClick={() => this.handleConfirm(false, 'interest')}>No</div>
            </div>
        </div>
    }

    getConfirmSubmit() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>Note: By default, Events are INACTIVE and must be Activated before they will be available to our Fans.</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleConfirmSubmit()}>OK</div>
            </div>
        </div>
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[id].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getTixForm() {
        //ToDo: add the submit button (function is done already), 
        //add security list (like auth users)
        //add the list below this section with a delete button, 
        //create that function
        //build the API
        //display the tix summary level detail (from API)
        //build out the fan experience
        //build out the security experience
        return <div className='Node-Row'>
            <div className="Node-Flex-Spread">
                <div className='Node-Row Node-Flex'>
                    <Form.Label className='Pref-Label' style={{ cursor: 'pointer' }} onClick={() => this.handleTixPay()}>Online Payment</Form.Label>
                    <Form.Check style={{ fontSize: '14pt', margin: '5px' }} type='checkbox' id='tixPay' label='' checked={this.state.tixPay ? true : false} onChange={() => this.handleTixPay()} />
                    {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                    {this.state.info[0].displayMsg && this.getInfo(0)}
                </div>
                <div className='Node-Row Node-Flex'>
                    <Form.Label className='Pref-Label' style={{ cursor: 'pointer' }} onClick={() => this.handleTixEmail()}>Additional Security</Form.Label>
                    <Form.Check style={{ fontSize: '14pt', margin: '5px' }} type='checkbox' id='tixEmail' label='' checked={this.state.tixEmail ? true : false} onChange={() => this.handleTixEmail()} />
                    {this.state.info[1].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(1)}><BsInfoCircle /></div>}
                    {this.state.info[1].displayMsg && this.getInfo(1)}
                </div>
            </div>
            <div className='Node-Row Node-Flex-Spread'>
                <div style={{ marginRight: '5px' }}>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Category *</Form.Label><br />
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px', height: '46px' }} value={this.state.tixCat} id='tixCat' onChange={this.handleChange}>
                            <option value=''>Select...</option>
                            {
                                this.state.tixCats.map((tc, tcidx) => {
                                    return <option key={`tc-${tcidx}`} value={tc}>{tc}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div>
                    <Form.Group controlId="tixLabel" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{`${this.state.tixCat} Label`}</Form.Label>
                        <Form.Control
                            placeholder={`${this.state.tixCat.length > 0 ? this.state.tixCat : 'Category'} Label (Group ${this.state.tix ? this.state.tix.length + 1 : '1'})`}
                            type="text"
                            value={this.state.tixLabel}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className='Node-Row Node-Flex-Spread'>
                <div style={{ marginRight: '5px' }}>
                    <Form.Group controlId="tixCount" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{`# ${this.state.tixCat} Tickets`}</Form.Label>
                        <Form.Control
                            placeholder="Available Tickets"
                            type="number"
                            value={this.state.tixCount}
                            onChange={this.handleChange}
                            isValid={this.getValidation('tixCount')}
                            isInvalid={!this.getValidation('tixCount')}
                        />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group controlId="tixLimit" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>{`Limit per Fan`}</Form.Label>
                        <Form.Control
                            placeholder="Tickets/Fan"
                            type="number"
                            value={this.state.tixLimit}
                            onChange={this.handleChange}
                            isValid={this.getValidation('tixLimit')}
                            isInvalid={!this.getValidation('tixLimit')}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className='Node-Row Node-Flex-Spread'>
                <div>
                    <Form.Group controlId="tixRate" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Ticket Price *</Form.Label>
                        <InputGroup>
                            <InputGroup.Text id="tp-addon1" style={{ fontSize: '14pt' }}>$</InputGroup.Text>
                            <Form.Control
                                placeholder="0.00"
                                type="number"
                                value={this.state.tixRate}
                                onChange={this.handleChange}
                                isValid={this.getValidation('tixRate')}
                                isInvalid={!this.getValidation('tixRate')}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
                <div></div>
            </div>
            <div className='Node-Row Node-Flex-Wrap'>
                <div className={this.tixFormIsValid() ? 'Tix-Button-Active' : 'Tix-Button'} onClick={() => this.handleAddTix()}>
                    <div className='Tix-Text'>Add Ticket Group</div>
                    <div className='Tix-Icon'><GiTicket /></div>
                </div>
            </div>
            <div className='Node-Row'>
                <Form.Group controlId="security" style={{ marginLeft: '5px' }} className="form-group form-group-lg">
                    <Form.Label className='Pref-Label'>Ticket Security Users</Form.Label>
                    <div className='Node-Flex'>
                        <Form.Control
                            placeholder="Gigloca User Email"
                            type="email"
                            value={this.state.security}
                            onChange={this.handleChange}
                            isValid={this.getValidation('security')}
                        />
                        <div className='My-Link-Icon' onClick={() => this.handleAddSecurity()}><FaPlusSquare /></div>
                        {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : null}
                    </div>
                </Form.Group>
                {
                    this.state.securityArray && this.state.securityArray.map((auth, auidx) => {
                        return <div key={`authu-${auidx}`} className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{auth.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${auth.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{auth.email}</div><div className='My-Link-Icon' onClick={() => this.handleRemoveArray('securityArray', auidx)}><AiFillCloseCircle /></div></div>
                    })
                }
            </div>
        </div>
    }

    getTix() {
        return <div className='Node-Row'>
            <div className='Node-Row Node-Flex-Spread Tix-Header'>
                <div>Tickets</div>
                <div className='Node-Flex'>
                    <div className='Tix-Icon'><GiTicket /></div>
                    <div className='Tix-Icon'>{this.state.tixPay ? <RiSecurePaymentFill /> : <MdOutlineCreditCardOff />}</div>
                    <div className='Tix-Icon'><BsQrCodeScan /></div>
                    <div className='Tix-Icon'>{this.state.tixEmail && <MdSecurityUpdateGood />}</div>
                </div>
            </div>
            <div className='Node-Row Node-Flex-Wrap'>
                {
                    this.state.tix && this.state.tix.map((t, tidx) => {
                        return <div key={`tix-${tidx}`} className='Tix-Container Tix-Container-2'>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Tix-Detail'>{t.label || ''}</div>
                                {this.state.view === 'edit' ? <div className='Tix-Icon' onClick={() => this.handleRemoveArray('tix', tidx)}><TbTicketOff /></div> : <div></div>}
                            </div>
                            <div className='Tix-Detail Tix-Count-2'>{t.remainingText || t.count}</div>
                            <div className='Tix-Detail Tix-Limit-2'>{t.limit || 'No'} per Fan Limit</div>
                            <div className='Tix-Detail Tix-Price-2'>{`$${t.price || '0.00'}`} per Ticket</div>
                            <div className='Tix-Detail Tix-Footer'>{t.cat || ''}</div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    getEdit() {
        //console.log(this.state)
        return <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Pref-Back Node-Flex-Spread'> <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
            <div className='Node-Row Node-Form'>
                <form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="title" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Event Title *</Form.Label>
                        <Form.Control
                            autoFocus
                            placeholder="Event Title"
                            type="text"
                            value={this.state.title}
                            onChange={this.handleChange}
                            isValid={this.getValidation('title')}
                            isInvalid={!this.getValidation('title')}
                        />
                    </Form.Group>
                    <div className='Node-Row'>
                        <Form.Group className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>Summary *</Form.Label>
                        </Form.Group>
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.summary} id='summary' onChange={this.handleChange}>
                            <option value=''>Select...</option>
                            {
                                this.state.summaryOpt && this.state.summaryOpt.map((o, oidx) => {
                                    return <option key={`nt-${oidx}`} value={o}>{o}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                    <Form.Group controlId="details" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Event Details *</Form.Label>
                        <Form.Control
                            placeholder="Event Descriptions"
                            as="textarea"
                            value={this.state.details}
                            rows={3}
                            onChange={this.handleChange}
                            isValid={this.getValidation('details')}
                            isInvalid={!this.getValidation('details')}
                        />
                    </Form.Group>
                    <Form.Group controlId="start" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Start Time *</Form.Label>
                        <Form.Control
                            placeholder="Start Time"
                            type="datetime-local"
                            value={this.state.start}
                            onChange={this.handleChange}
                            isValid={this.getValidation('start')}
                            isInvalid={!this.getValidation('start')}
                        />
                    </Form.Group>
                    <Form.Group controlId="end" className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>End Time *</Form.Label>
                        <Form.Control
                            placeholder="End Time"
                            type="datetime-local"
                            value={this.state.end}
                            onChange={this.handleChange}
                            isValid={this.getValidation('end')}
                            isInvalid={!this.getValidation('end')}
                        />
                    </Form.Group>
                    <div className='Node-Row Node-Flex-Wrap'>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(0)}>
                            {
                                this.state.imageArray[0] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[0].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 0)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '0' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(1)}>
                            {
                                this.state.imageArray[1] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[1].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 1)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '1' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(2)}>
                            {
                                this.state.imageArray[2] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[2].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 2)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '2' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='My-Img-Container' onClick={() => this.handleClearPic(3)}>
                            {
                                this.state.imageArray[3] ? <img className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.imageArray[3].imgUrl} alt='' /> :
                                    <label>
                                        <input style={{ display: 'none' }} type="file" onChange={event => this.handleImageChange(event, 3)} ref={ref => this.fileInput = ref} />
                                        <div style={{ paddingTop: '60px' }}>{this.state.imageSize === '3' ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                                    </label>
                            }
                        </div>
                        <div className='Node-Row'>
                            <Form.Group className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Event Amenities</Form.Label>
                            </Form.Group><div className='Node-Flex-Left'>
                                {
                                    (this.state.options.filter(o => o.ptype === 'Venue')[0].amenities || []).map((a, aidx) => {
                                        return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`amenity-${aidx}`} type='checkbox' id={a} label={a} checked={this.getOpt('amenities', a)} onChange={() => this.handleOptionsChange('amenities', a)} />
                                    })
                                }</div>
                            <Form.Group className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Event Restrictions</Form.Label>
                            </Form.Group><div className='Node-Row Node-Flex-Left'>
                                {
                                    (this.state.options.filter(o => o.ptype === 'Venue')[0].restrictions || []).map((r, ridx) => {
                                        return <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} key={`restrict-${ridx}`} type='checkbox' id={r} label={r} checked={this.getOpt('restrictions', r)} onChange={() => this.handleOptionsChange('restrictions', r)} />
                                    })
                                }</div>
                        </div>
                        <div className='Node-Row'>
                            <Form.Group className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Slots</Form.Label>
                            </Form.Group>
                            <div className='Node-Row'>
                                <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.node} id='node' onChange={this.handleChange}>
                                    <option value=''>Select Contributor Type...</option>
                                    {
                                        this.state.options && this.state.options.filter(opt => opt.ptype !== 'Venue').map((o, oidx) => {
                                            return <option key={`nt-${oidx}`} value={o.ptype}>{o.ptype}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                            <div className='Node-Row'>
                                <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.category} id='category' onChange={this.handleChange}>
                                    <option value=''>Select Category...</option>
                                    {
                                        this.state.node && this.state.options && this.state.options[this.state.options.findIndex(o => o.ptype === this.state.node)].categories.map((cat, cidx) => {
                                            return <option key={`cat-${cidx}`} value={Object.keys(cat)[0]}>{Object.keys(cat)[0]}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                            <Form.Group controlId="description" className="form-group form-group-lg">
                                <div className='Node-Flex-Spread'>
                                    <Form.Label className='Pref-Label'>Slot Details</Form.Label>
                                    <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} type='checkbox' id='prebooked' label='Pre-booked' checked={this.state.prebooked} onChange={() => this.handleOptionChange('prebooked')} />
                                </div>
                                <Form.Control
                                    placeholder="Slot Description"
                                    as="textarea"
                                    value={this.state.description}
                                    rows={3}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <div className='Node-Row Pref-Cancel-Button' onClick={() => this.handleAddSlot()}>Add Slot</div>
                        </div>
                        {this.state.slots && <div className='Node-Row Node-Flex-Wrap'>
                            {this.state.slots.map((slot, sidx) => {
                                return <div key={`slot-${sidx}`} className='My-Slot-Container'>
                                    <div className={`My-Img-Container`} onClick={() => this.handleRemoveSlot(sidx)}>
                                        <div style={{ fontSize: '14pt' }} className='Node-Row-Thin'>{slot.node}</div>
                                        <div className='Node-Row'>{this.getIcon(slot.category)}</div>
                                        <div style={{ fontSize: '14pt' }} className='Node-Row-Thin'>{slot.category}</div>
                                        <div className='Node-Row'>{slot.selected && slot.selected.length > 0 ? <FaClipboardCheck /> : (slot.interested && slot.interested.length > 0 ? <FaClipboardList /> : <FaRegClipboard />)}</div>
                                    </div>
                                    {slot.description && <div className='My-Slot-Descr'>{slot.description}</div>}
                                </div>
                            })}
                        </div>}
                        {true && <div className='Node-Row Node-Flex'>
                            <Form.Label className='Pref-Label' style={{ cursor: 'pointer' }} onClick={() => this.handleTix()}>Ticketed Event</Form.Label>
                            <Form.Check style={{ fontSize: '14pt', margin: '5px', minWidth: '150px' }} type='checkbox' id='tix' label='' checked={this.state.tix ? true : false} onChange={() => this.handleTix()} />
                        </div>}
                        {this.state.tix && this.getTixForm()}
                        {this.state.tix && this.getTix()}
                        <div className='Node-Row'>
                            <LoaderButton
                                size="lg"
                                disabled={!this.formIsValid()}
                                type="submit"
                                isLoading={this.state.isSaving}
                                text={this.state.eventId ? 'Update' : 'Create'}
                                loadingText={this.state.eventId ? 'Updating...' : 'Creating...'}
                                className='Signup-Button'
                            />
                            <div className='Node-Row Pref-Cancel-Button' onClick={() => this.handleCancel()}>Cancel</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    }

    getView() {
        return this.state.event && <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Node-Content-2'>
                {this.state.event.imageArray && this.state.event.imageArray.length > 0 ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                    {
                        this.state.event.imageArray.map((ci, imgidx) => {
                            return <Carousel.Item key={`ci-${imgidx}`}>
                                <img src={ci.imgUrl ? ci.imgUrl : `https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.event.title || this.state.title || ''} />
                            </Carousel.Item>
                        })
                    }
                </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getIcon(this.state.event && this.state.event.summary ? this.state.event.summary : null)}</div>}
                <div className='Node-Back Node-Flex-Spread'>
                    <div className='Node-Arrow' onClick={() => this.handleBack()}>
                        <div style={{ marginTop: '-13px' }}><FaArrowLeft /></div>
                    </div>
                    {this.state.eventId && this.state.eventId !== 'new' && <div className='Node-Flex'>
                        <div className='Node-Arrow' style={{ marginRight: '5px', color: this.state.event.active ? 'green' : '#b3b3b3' }} onClick={() => this.handleActivate()}><div style={{ marginTop: '-13px' }}>{this.state.event.active ? <MdOutlineVerified /> : <GoUnverified />}</div></div>
                        <div className='Node-Arrow' style={{ marginRight: '5px' }} onClick={() => this.handleDeleteEvent()}><div style={{ marginTop: '-13px', color: '#ff0000' }}><FaTimesCircle /></div></div>
                        <div className='Node-Arrow' onClick={() => this.handleClone()}><div style={{ marginTop: '-13px', color: '#5989f0' }}><LuCopyPlus /></div></div>
                    </div>}
                </div>
                <div className='Node-Content-3'>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Title'>{this.state.event.title ? this.state.event.title : 'Event'}</div>
                        {(this.state.event.attending || this.state.event.interested) && ((this.state.event.attending || 0) + (this.state.event.interested || 0) > 0) ? <div>
                            <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                            <div className='Node-Heart-Text'>{(this.state.event.attending || 0) + (this.state.event.interested || 0) > 1000 ? `${(((this.state.event.attending || 0) + (this.state.event.interested || 0)) / 1000).toFixed(1)}k` : (this.state.event.attending || 0) + (this.state.event.interested || 0)}</div>
                        </div> : null}
                    </div>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Event Schedule</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.startTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.startTime).format('h:mm A') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.event.stopTime ? moment(this.state.event.stopTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.stopTime).format('h:mm A') : ''}</div>
                        </div>
                    </div>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Venue</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Row Node-Summary'>{this.state.venue && this.state.venue.name ? this.state.venue.name : 'Venue'}</div>
                    {this.state.venue && this.state.venue.address && <div className='Node-Row Node-Flex'>
                        <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                        <div className='Node-Addr'>{this.state.venue.address}</div>
                    </div>}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Summary</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Row Node-Summary'>{this.state.event.summary || 'summary'}</div>
                    {this.state.event.weather && this.state.event.weather[0] && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Weather Forecast</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.weather && this.state.event.weather[0] && <div className='Node-Row Node-Flex-Left-NoWrap'>
                        <img src={`https://openweathermap.org/img/wn/${this.state.event.weather[0].weather[0].icon}@2x.png`} className='Event-Weather-Icon' alt={this.state.event.weather[0].weather[0].main} />
                        <div className='Event-Weather-Text'>{this.state.event.weather[0].summary} {`(${temp(this.state.event.weather[0].temp.min)}F - ${temp(this.state.event.weather[0].temp.max)}F)`}</div></div>}
                    {this.state.event.details && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Details</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.details && <div className='Node-Row'>
                        <div className='Node-Details'>{this.state.event.details}</div>
                    </div>}
                    {this.state.event.amenities && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Amenities</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.amenities && <div className='Node-Row Node-Flex-Wrap'>
                        {
                            this.state.event.amenities.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.event.restrictions && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Restrictions</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.restrictions && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.event.restrictions.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text Node-Flex' onClick={() => this.handleAddPost()}>Posts from {this.state.data ? this.state.data.name : ''} <div style={{ marginLeft: '5px', fontSize: '16pt' }}><BiMessageAdd /></div></div>
                        <div className='Node-Divider'></div>
                        {this.state.event.posts && this.state.event.posts.length > 0 && <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>}
                    </div>
                    {this.state.displayPost && <div className='Rate-Container'>
                        <form onSubmit={this.handleSavePost}>
                            <div className='Node-Row'><Form.Group controlId="post" className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Post</Form.Label>
                                <Form.Control
                                    autoFocus
                                    placeholder="New Post"
                                    as="textarea"
                                    rows="5"
                                    value={this.state.post}
                                    onChange={event => this.handleChange(event)}
                                />
                            </Form.Group></div>
                            <div className='Node-Row Node-Flex-Spread' style={{ marginTop: '30px' }}>
                                <div style={{ width: '160px', height: '60px' }}><LoaderButton
                                    size="lg"
                                    disabled={!this.postIsValid()}
                                    type="submit"
                                    isLoading={this.state.isSaving}
                                    text='Post'
                                    loadingText='Saving...'
                                    className='Signup-Button'
                                    style={{ margin: '0px', bottom: '0' }}
                                /></div>
                                <div style={{ width: '160px', fontSize: '18pt', fontWeight: 'bold' }} className='Pref-Cancel-Button' onClick={() => this.handleCancelPost()}>Cancel</div>
                            </div>
                        </form>
                    </div>}
                    {this.state.event.posts && this.state.event.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                        {this.state.event.posts.sort((a, b) => {
                            if (moment(a.created).isAfter(moment(b.created))) return -1
                            if (moment(a.created).isBefore(moment(b.created))) return 1
                            return 0
                        }).map((post, pidx) => {
                            return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                <div className='Node-Row Node-Flex'>
                                    <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                    <div className='My-Review-Text'>{post.post}</div>
                                    <div>
                                        <div style={{ width: '75px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                        <div className='Post-Delete-Icon' onClick={() => this.handleDelPost(post.id)}><MdDeleteForever /></div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div> : null}
                    {this.state.event && this.state.event.slots && <div>
                        <div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Slots</div>
                            <div className='Node-Divider'></div>
                        </div>
                        {
                            this.state.event.slots.map((slot, sidx) => {
                                return <div key={`slot-${sidx}`} className='Node-Slots-Container'>
                                    <div className='Node-Row Node-Flex-Wrap Node-Title'>
                                        <div className=''>{slot.node}</div>
                                        <div className=''>{this.getIcon(slot.category)}</div>
                                        <div className=''>{slot.category}</div>
                                    </div>
                                    {slot.description && <div className='Node-Slot-Desc'>{slot.description}</div>}
                                    {!slot.open && <div className='Node-Slot-Container'>
                                        <div className='Node-Row Node-Flex-Spread'>
                                            <div className='Node-Divider'></div>
                                            <div className='Node-Divider-Text'>Accepted</div>
                                            <div className='Node-Divider'></div>
                                        </div>
                                        {slot.prebooked ? <div className='Node-Row Node-Flex-Wrap'>
                                            <div className='Node-Title'>Pre-booked</div>
                                        </div> : <div className='Node-Row Node-Flex-Wrap'>
                                            {this.state.event.nodes && this.state.event.nodes.findIndex(n => n.id === slot.selected) > -1 && <SmallCard type='contributor' data={this.state.event.nodes.filter(n => n.id === slot.selected)[0]} onSelect={this.handleNodeSelect} onDismiss={this.handleAccept} slot={slot.idx} />}
                                        </div>}
                                    </div>}
                                    {slot.interested && slot.interested.length > 0 && <div className='Node-Slot-Container'>
                                        <div className='Node-Row Node-Flex-Spread'>
                                            <div className='Node-Divider'></div>
                                            <div className='Node-Divider-Text'>Available</div>
                                            <div className='Node-Divider'></div>
                                        </div>
                                        <div className='Node-Row Node-Flex-Wrap'>{slot.interested.map((si, siidx) => {
                                            return <SmallCard key={`node-${siidx}`} type='contributor' data={si} onSelect={this.handleNodeSelect} onAccept={slot.open ? this.handleAccept : null} slot={slot.idx} />
                                        })}</div>
                                    </div>}
                                    {slot.open && (slot.interested || []).length === 0 && <div className='Node-Slot-Container'>
                                        <div className='Node-Row Node-Flex-Spread'>
                                            <div className='Node-Divider-Text'>Open</div>
                                            <div className='Node-Divider'></div>
                                            <div className='Node-Divider-Button' style={{ fontSize: '11pt', fontWeight: 'bold' }} onClick={() => this.handleFindSlotNodes(slot.node, slot.category, slot.idx)}>Find<MdPersonSearch /></div>
                                        </div>
                                    </div>}
                                    {this.state.slotNodes && this.state.slotNodes[slot.idx] && this.state.slotNodes[slot.idx].length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.slotNodes[slot.idx].map((ai, aiidx) => {
                                        return <SmallCard key={`node-${aiidx}`} type='contributor' data={ai} onSelect={this.handleNodeSelect} onAccept={this.handleRequestInterest} slot={slot} name={ai.name} />
                                    })}</div>}
                                    {this.state.event.securityArray && this.state.event.securityArray.map((auth, auidx) => {
                                        return <div key={`authu-${auidx}`} className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{auth.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${auth.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{auth.email}</div></div>
                                    })}
                                </div>
                            })
                        }
                        {this.state.msg ? <div className='Node-Row Node-Msg'>{this.state.msg}</div> : (this.state.event.slots.filter(s => s.open).length > 0 ? <div className='Node-Ping-Button' onClick={() => this.handleFindNodes()}>Find & Share Event with Available Contributors</div> : null)}
                        {this.state.nodes.length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.nodes.map((node, nidx) => {
                            return <SmallCard key={`node-${nidx}`} type='contributor' data={node} onSelect={this.handleNodeSelect} />
                        })}</div>}
                    </div>}
                    {this.state.tix && this.getTix()}
                    {this.state.eventId && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/event/${this.state.eventId}`} /></div>}
                </div>
            </div>
        </div>
    }

    render() {
        //console.log(this.state)
        return <div className={`My-Container Node-Event`}>
            <TopMenu
                mode={this.props.mode}
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {
                this.state.splash ? this.getSplash() : (this.state.bounce ? this.getBounce() : (this.state.view === 'edit' ? this.getEdit() : this.getView()))
            }
            {
                this.state.displayConfirm && this.getConfirm()
            }
            {
                this.state.displayMsg && this.getMessage()
            }
            {
                this.state.displayConfirmSubmit && this.getConfirmSubmit()
            }
            <BottomMenu
                menu={this.state.menu}
                onLeft={this.handleLeftMenu}
                onRight={this.handleRightMenu} />

        </div>
    }
}

export default withRouter(MyEvent)